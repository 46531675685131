import { EventInteraction, EventInteractionContext } from '@eventbrite/ads';
import { FormattedEvent } from '@eventbrite/event-renderer';
import { computeLocationInfo } from '@eventbrite/search-utils';
import { getBaseTrackingContext } from '../../../utils/tracking';

export const SEARCH_PLACEMENT_NAME = 'search';
export const SPONSORED_ADS_SUBINTERFACE = 'sponsored_bucket_ads';
export const SEARCH_CONTENT_SUBINTERFACE = 'search_results';

export function aggregateTrackingContext({
    event,
    eventSearch,
    searchId,
    events = [],
    userId,
    locale,
    sessionId,
    guestId,
    subInterface,
}: Omit<EventInteraction, 'action'> & {
    eventSearch: any;
    searchId?: string;
    events: FormattedEvent[] | undefined;
    userId?: string;
    locale?: string;
    sessionId?: string;
    guestId?: string;
    subInterface: string;
}): EventInteractionContext {
    const baseTrackingContext = getBaseTrackingContext({
        event,
        events,
        userId,
        locale,
        guestId,
        sessionId,
    });

    return {
        ...baseTrackingContext,
        place: {
            name: SEARCH_PLACEMENT_NAME,
            page: eventSearch.page || 0,
            context: {
                date_filter: eventSearch.dates || null,
                query_terms: eventSearch.q || null,
                search_id: searchId,
                ...computeLocationInfo(eventSearch),
                tags: event.tags || [],
            },
            subInterface: {
                name: subInterface,
            },
            ...baseTrackingContext.place,
        },
    };
}
