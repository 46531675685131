import { EventSearch, SearchThirdAd } from '@eventbrite/discover-utils';
import { DestinationEvent } from '@eventbrite/event-renderer';
import {
    getEventSearchQueryKey,
    parseSearchDataToEvents,
    SearchResponseEvents,
} from '@eventbrite/search-utils';
import { useExperiment } from '@eventbrite/statsig';
import { $FixMe } from '@eventbrite/ts-utils';
import * as Sentry from '@sentry/browser';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect } from 'react';
import { search as searchApi, SearchApiResponse } from '../../redux/api/search';

export { getEventSearchQueryKey };

export type SearchDataParams = {
    eventSearch: EventSearch;
    searchContext: {
        experimentOverride: Record<string, $FixMe>;
        isStaff: boolean;
    };
    onNewData: (data: SearchCachedData) => $FixMe;
    useBff?: boolean;
};

export type UseSearchDataResponse = SearchResponseEvents & {
    isLoading: boolean;
    error: unknown;
};

export type SearchCachedData = SearchResponseEvents &
    Pick<SearchApiResponse, 'metrics'>;

export function useSearchData({
    eventSearch,
    searchContext,
    onNewData,
    useBff = false,
}: SearchDataParams): UseSearchDataResponse {
    const adSlotVariant = useExperiment(
        SearchThirdAd.name,
        SearchThirdAd.paramName,
        SearchThirdAd.values.control,
    );

    const {
        data = placeholderSearchData,
        isFetching,
        error,
    } = useQuery({
        queryKey: getEventSearchQueryKey(eventSearch),
        queryFn: () =>
            searchApi({
                searchQuery: eventSearch,
                adSlots:
                    adSlotVariant.value === SearchThirdAd.values.increaseAds
                        ? 3
                        : 2,
                ...searchContext,
                useBff,
            }),
        // Mimic previous behaviour
        staleTime: 3 * 60 * 1000,
        onError: (e: unknown) => handleSearchError(e, { eventSearch }),
        select: (apiData) => ({
            ...parseSearchDataToEvents(apiData.data),
            metrics: apiData.metrics,
        }),
    });

    useEffect(() => {
        // Wait until fetching is done
        if (isFetching || !data) {
            return;
        }

        onNewData(data);
    }, [onNewData, isFetching, data]);

    return {
        events: data.events,
        pagination: data.pagination,
        isLoading: isFetching,
        error,
    };
}

const placeholderSearchData: SearchCachedData = {
    events: {
        organic: [],
        topMatches: [],
        promoted: [],
    },
    pagination: {
        page: 1,
        pageSize: 0,
        pageCount: 1,
        objectCount: 0,
        continuation: '',
    },
};

export function useRemovePromotedSearchEvent(eventSearch: EventSearch) {
    const client = useQueryClient();

    return {
        removeEvent: (eventId: string) => {
            client.setQueryData<SearchApiResponse>(
                getEventSearchQueryKey(eventSearch),
                (apiResponse) => {
                    if (!apiResponse) {
                        return apiResponse;
                    }

                    const promotedEvents =
                        apiResponse.data.events.promoted_results;

                    const newApiResponse = cloneDeep(apiResponse);
                    newApiResponse.data.events.promoted_results =
                        promotedEvents.filter(({ id }) => eventId !== id);

                    return newApiResponse;
                },
            );
        },
    };
}

export function useCurrentSearchOrganicEvents(
    eventSearch: EventSearch,
): DestinationEvent[] {
    const queryClient = useQueryClient();
    const events = queryClient.getQueryData<SearchApiResponse>(
        getEventSearchQueryKey(eventSearch),
    );
    return events?.data.events.results || [];
}

function handleSearchError(
    e: unknown,
    { eventSearch }: Pick<SearchDataParams, 'eventSearch'>,
) {
    const isNetworkError =
        typeof Response !== 'undefined' && e instanceof Response;

    if (isNetworkError) {
        if (e.status < 500) {
            reportNetworkError(e.statusText, {
                search_body: eventSearch,
                status_code: e.status,
            });
        }
    } else {
        reportNetworkError(e, { search_body: eventSearch });
    }
}

function reportNetworkError(title: unknown, context: Record<string, unknown>) {
    Sentry.captureException(title, {
        extra: context,
    });
}
