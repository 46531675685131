import loadable from '@loadable/component';
import { isEmpty } from 'lodash';
import React, { FunctionComponent, useMemo } from 'react';
import { connect } from 'react-redux';
import { SearchResponseEvents } from '../../../../utils/transformation';
import { FilterPanelLoading } from '../../components/FilterPanel/FilterPanelLoading';
import { FilterHeaderContainer } from '../FilterHeaderContainer/FilterHeaderContainer';
import { SearchFooterContainer } from '../SearchFooterContainer';
import { SearchHeaderContainer } from '../SearchHeaderContainer';
import { SearchPivotsContainer } from '../SearchPivotsContainer';
import { SearchResultPanelContent } from '../SearchResultPanelContent';
import './SearchResultsPanelContainer.scss';

const LazyTopMatchContainer = loadable(() => import('../TopMatchContainer'), {
    resolveComponent: (components: { TopMatchContainer: React.ReactElement }) =>
        components.TopMatchContainer,
});

const FilterPanel = loadable(() => import('../FilterPanelContainer'), {
    fallback: <FilterPanelLoading />,
});

type ResultsPanelProps = SearchResponseEvents & {
    isLoading: boolean;
};

const ResultsPanel = React.memo(
    ({ events, pagination, isLoading }: ResultsPanelProps) => {
        const hasTopMatch = !isEmpty(events.topMatches) && !isLoading;
        const sortedEvents = useMemo(
            () => [...events.promoted, ...events.organic],
            [events],
        );

        return (
            <div className="search-results-panel-content">
                <section key={`marmalade-filters`}>
                    <SearchHeaderContainer />

                    <div style={{ display: 'flex' }}>
                        <FilterPanel isLoading={isLoading} />
                        <div className="search-results-panel-content__events">
                            <FilterHeaderContainer />
                            {hasTopMatch && (
                                <LazyTopMatchContainer
                                    events={events.topMatches}
                                />
                            )}
                            <SearchResultPanelContent
                                isLoading={isLoading}
                                events={sortedEvents}
                            />
                            <SearchPivotsContainer
                                isLoading={isLoading}
                                pagination={pagination}
                            />
                            <SearchFooterContainer pagination={pagination} />
                        </div>
                    </div>
                </section>
            </div>
        );
    },
);

export const _mapStateToProps = (state: any) => {
    return {
        eventSearch: state.search.eventSearch,
        searchId: state.search.searchId,
    };
};

export const SearchResultsPanelContainer: FunctionComponent<ResultsPanelProps> =
    connect(_mapStateToProps)(ResultsPanel);
