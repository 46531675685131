import { Icon } from '@eventbrite/eds-icon';
import { FilterChunky } from '@eventbrite/eds-iconography';
import { STYLE_OUTLINED, Tag } from '@eventbrite/eds-tag';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import React from 'react';

interface ActiveFilterElementProps {
    numberActiveFiltersLabel: null | GenericLazyString;
    numberActiveFilters: number;
    openCloseAnimatedDrawer: Function;
}

export const FilterHeaderPillsToggle: React.FunctionComponent<
    ActiveFilterElementProps
> = (props) => {
    const hasActiveFilters = props.numberActiveFilters > 0;

    return (
        <>
            <div className="number-active-filters">
                <span className="number-active-filters--label">
                    {props.numberActiveFiltersLabel}
                </span>
                <span
                    className="number-active-filters--button"
                    data-testid="more-filters-facet"
                >
                    <Tag
                        initialIsActive={false}
                        onClickStatic={() => {
                            props.openCloseAnimatedDrawer(true);
                        }}
                        backgroundColor={
                            hasActiveFilters
                                ? 'eds-bg-color--ui-blue'
                                : undefined
                        }
                        style={STYLE_OUTLINED}
                    >
                        <span
                            className={`tag-content ${
                                hasActiveFilters
                                    ? 'eds-text-color--white'
                                    : 'eds-text-color--grey-700'
                            }`}
                        >
                            <Icon
                                type={<FilterChunky />}
                                color={hasActiveFilters ? 'white' : 'grey-700'}
                            />
                            {gettext('Filters')}
                            {hasActiveFilters
                                ? ` (${props.numberActiveFilters})`
                                : null}
                        </span>
                    </Tag>
                </span>
            </div>
        </>
    );
};
