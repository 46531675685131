import { type WithRouterProps } from '@eventbrite/search-utils';
import queryString from 'query-string';
import React from 'react';
import {
    Link as ReactRouterLink,
    LinkProps as ReactRouterLinkProps,
    RouteComponentProps,
    withRouter as withReactRouter,
} from 'react-router-dom';

/**
 * Abstraction of the Link component.
 * Replicates the interface of the next/router `Link` Component to be used with react-router.
 */
export interface LinkProps extends Omit<ReactRouterLinkProps, 'to'> {
    href: string;
}
export const Link = ({ href, ...rest }: React.PropsWithChildren<LinkProps>) => (
    <ReactRouterLink to={href} {...rest} />
);

export function withRouter<T extends WithRouterProps = WithRouterProps>(
    Component: React.ComponentType<T>,
) {
    const Wrapper = (
        props: Omit<T & RouteComponentProps, keyof WithRouterProps>,
    ) => {
        const { history, location } = props;

        // Convert react-router props to nearly mimic the shape of next/router
        // https://nextjs.org/docs/pages/api-reference/functions/use-router
        const pathname = location.pathname;
        const query = queryString.parse(location.search);
        const asPath = pathname + location.search;
        const isFallback = false;
        const basePath = '/d';
        const push = history.push;

        return (
            <Component
                {...(props as T)}
                router={{
                    pathname,
                    query,
                    asPath,
                    isFallback,
                    basePath,
                    push,
                }}
            />
        );
    };

    return withReactRouter(Wrapper);
}
