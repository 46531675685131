import { useCallback, useEffect, useRef } from 'react';
import {
    EventInteractions,
    HTMLElementWithEvents,
    InteractionCustomEvent,
    PLACE_EVENT_INTERACTION,
} from '../types';

export function useCurrentImpressionsNotVisited(
    baseElement: HTMLElementWithEvents | undefined,
    dependencyArray: any[],
) {
    const impressions = useRef(new Map<string, InteractionCustomEvent>());

    const mapImpressions = useCallback(
        (event: InteractionCustomEvent) => {
            switch (event.detail.action) {
                case EventInteractions.Impression:
                    impressions.current.set(event.detail.id, event);
                    break;
                case EventInteractions.Click:
                    impressions.current.delete(event.detail.id);
                    break;
            }
        },
        [impressions],
    );

    useEffect(
        () => {
            impressions.current = new Map();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        dependencyArray,
    );

    useEffect(() => {
        const domElement = baseElement || document;

        domElement.addEventListener(
            PLACE_EVENT_INTERACTION as any,
            mapImpressions,
        );
        return () =>
            domElement.removeEventListener(
                PLACE_EVENT_INTERACTION as any,
                mapImpressions,
            );
    }, [baseElement, mapImpressions]);

    return impressions;
}
