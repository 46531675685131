import {
    EventInteractions,
    HTMLElementWithEvents,
    InteractionCustomEvent,
    PLACE_EVENT_INTERACTION,
} from '../types';
import {
    listingsBroadcastChannel,
    TabTrackerAction,
    TabTrackerMessagePayload,
} from './channels';
import { useCurrentImpressionsNotVisited } from './useCurrentImpressions';
import { useExternalTabChannel } from './useExternalTabChannel';

/*
 * Due to the nature of browsers, new tab actions are untrackable via browser events
 * In order to sort this limitation, we have a simple broadcast system between the surface we want to track and our listings page
 * If the user visits the listing page of an event and this event is visible it will trigger an event
 * This approach is conversative by nature, if the user closes either the search or the listings page, this event will not be emitted
 * In order to filter false positives we only allow 1 open-external event emission for each impression
 * This approach is experimental and may change in the future: https://eventbrite.atlassian.net/browse/EB-224895
 */
export function useExternalTabTracker(
    interfaceUuid: string,
    dependencyArray: any[] = [],
    baseElement?: HTMLElementWithEvents,
) {
    const impressions = useCurrentImpressionsNotVisited(
        baseElement,
        dependencyArray,
    );

    const onMessage = async (
        broadcastMessage: MessageEvent<TabTrackerMessagePayload>,
    ) => {
        const relatedImpressionEvent = impressions.current.get(
            broadcastMessage.data.eventId,
        );

        // If it doesn't match any id, we ignore this broadcast message
        if (!relatedImpressionEvent) {
            return;
        }

        switch (broadcastMessage.data.action) {
            case TabTrackerAction.request:
                const eventId = broadcastMessage.data.eventId;
                channel?.postMessage({
                    action: TabTrackerAction.ack,
                    eventId,
                    uuid: interfaceUuid,
                });
                break;

            case TabTrackerAction.confirm:
                if (broadcastMessage.data.uuid !== interfaceUuid) {
                    return;
                }

                impressions.current.delete(broadcastMessage.data.eventId);
                emitOpenEvent(relatedImpressionEvent);
                break;
        }
    };

    const channel = useExternalTabChannel(listingsBroadcastChannel, {
        onMessage,
    });

    return {
        channel,
        impressions,
    };
}

function emitOpenEvent(relatedImpressionEvent: InteractionCustomEvent) {
    const openEvent = new CustomEvent(PLACE_EVENT_INTERACTION, {
        detail: {
            ...relatedImpressionEvent.detail,
            action: EventInteractions.Click,
        },
        bubbles: true,
    });

    relatedImpressionEvent.target?.dispatchEvent(openEvent);
}
