import * as React from 'react';

const FilterChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="filter-chunky_svg__eds-icon--filter-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="filter-chunky_svg__eds-icon--filter-chunky_bottom"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 19v2H6v-2H4v-2h2v-2h2v2h12v2H8z"
        />
        <path
            id="filter-chunky_svg__eds-icon--filter-chunky_middle"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 13v2h-2v-2H4v-2h12V9h2v2h2v2z"
        />
        <path
            id="filter-chunky_svg__eds-icon--filter-chunky_top"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 7v2H6V7H4V5h2V3h2v2h12v2z"
        />
    </svg>
);

FilterChunkySvg.displayName = 'FilterChunkySvg';
export default FilterChunkySvg;
