import { FeatureFlags } from '../../../types';

/**
 * Determines if the search BFF should be used for the given API.
 * The feature flag can be set to 'on' to enable it for all supported routes.
 * This also supports the legacy boolean flag.
 */
export const useSearchBff = (
    enableSearchBff: FeatureFlags['enableSearchBff'],
    api: 'events' | 'search',
) => {
    return (
        !!enableSearchBff &&
        (typeof enableSearchBff === 'boolean'
            ? enableSearchBff
            : enableSearchBff === api || enableSearchBff === 'on')
    );
};
