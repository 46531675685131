import * as React from 'react';

const MegaphoneThinSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={32} height={32} fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.642 11.007c.134-1.374.358-2.597.644-3.585.241-.835.52-1.474.806-1.893.298-.435.53-.529.658-.529.13 0 .36.094.658.529.287.419.565 1.058.806 1.893C26.696 9.084 27 11.41 27 14c0 2.59-.304 4.916-.786 6.578-.241.835-.52 1.474-.806 1.893-.298.435-.529.529-.658.529s-.36-.094-.658-.529c-.286-.419-.564-1.058-.806-1.893-.252-.87-.456-1.922-.592-3.098C24.086 17.293 25 15.807 25 14.247c0-1.58-.936-3.084-2.358-3.24zm-.08.998a31.868 31.868 0 00.034 4.477C23.3 16.354 24 15.508 24 14.247c0-1.282-.724-2.136-1.438-2.242zm-.12-5.246c-.675.463-1.398.887-2.152 1.273a19.502 19.502 0 00-.551 2.58.5.5 0 01-.99-.145c.095-.647.215-1.272.359-1.872-.857.379-1.743.713-2.638 1C13.574 10.529 10.544 11 8.006 11H8a3 3 0 100 6c2.539 0 5.574.471 8.473 1.404.9.29 1.791.625 2.653 1.007-.402-1.649-.626-3.497-.626-5.445a.5.5 0 01.995-.068l.84 6.09c.737.378 1.444.794 2.106 1.247-.04-.123-.078-.25-.116-.378-.514-1.776-.825-4.2-.825-6.857s.311-5.08.825-6.857c.038-.13.077-.259.118-.384zm1.109-2.155a.498.498 0 00-.035.03c-1.894 1.76-4.543 3.105-7.353 4.01C13.357 9.548 10.427 10 8 10h-.006A4 4 0 008 18c.483 0 .985.018 1.503.054l.82 7.792c.13 1.24 1.211 2.154 2.466 2.154H15a.5.5 0 00.49-.602l-.976-4.675.968-3.542a.56.56 0 00.008-.033c.226.066.452.136.677.208 2.811.905 5.462 2.25 7.355 4.01a.259.259 0 00.008.007c.326.364.731.627 1.22.627.63 0 1.123-.438 1.483-.964.371-.543.686-1.295.942-2.18.514-1.775.825-4.2.825-6.856 0-2.657-.311-5.08-.825-6.857-.256-.884-.57-1.636-.942-2.179-.36-.526-.852-.964-1.483-.964-.478 0-.877.252-1.2.604zM11.318 25.741l-.8-7.596c1.291.142 2.651.388 4.01.738a.55.55 0 00-.01.034l-1 3.658a.5.5 0 00-.007.234L14.385 27h-1.596c-.777 0-1.398-.561-1.471-1.259z"
            fill="#535163"
        />
    </svg>
);

MegaphoneThinSvg.displayName = 'MegaphoneThinSvg';
export default MegaphoneThinSvg;
