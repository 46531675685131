import { Icon } from '@eventbrite/eds-icon';
import { ChevronDownChunky } from '@eventbrite/eds-iconography';
import { STYLE_DEFAULT, STYLE_OUTLINED, Tag } from '@eventbrite/eds-tag';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import { Button as MarmaladeButton } from '@eventbrite/marmalade';
import {
    CATEGORY_FILTER,
    CURRENCY_FILTER,
    DATE_FILTER,
    EXPERIENCES_FILTER,
    FILTER_PILLS_DISMISS_MAP,
    FORMAT_FILTER,
    LANGUAGE_FILTER,
    PRICE_FILTER,
    SUBCATEGORIES_FILTER,
} from '@eventbrite/search-utils';
import classNames from 'classnames';
import React from 'react';
import './FilterHeaderPills.scss';
import { FilterHeaderPillsToggle } from './FilterHeaderPillsToggle';

interface IGetRenderedFilters {
    date?: string;
    format?: string;
    price?: string;
    subcategories?: string[];
    category?: string;
    language?: string;
    currency?: string;
    onClose?: Function;
    online?: boolean;
    organizations?: string[];
    experiences?: string[];
    hash?: string;
    followedOrganizers?: boolean;
    hashedEventTitle?: string | GenericLazyString;
    shouldRenderInactivePills?: boolean;
    inactivePillsRenderSet: Set<string>;
    onClick?: (key: string) => void;
    isExperienceFilterExperiment?: boolean;
}

export const INTTERACTIVE_TAG_BACKGROUND_COLOR = 'eds-bg-color--ui-blue';

const ALWAYS_RENDER_FILTER_SET = new Set([
    DATE_FILTER,
    CATEGORY_FILTER,
    FORMAT_FILTER,
    PRICE_FILTER,
    LANGUAGE_FILTER,
    CURRENCY_FILTER,
]);

const getRenderedFilters = ({
    date,
    format,
    price,
    subcategories,
    category,
    language,
    currency,
    online,
    organizations,
    experiences,
    hash,
    followedOrganizers,
    onClose,
    hashedEventTitle,
    shouldRenderInactivePills,
    inactivePillsRenderSet,
    onClick,
    isExperienceFilterExperiment,
}: IGetRenderedFilters) => {
    const filterObject: { [key: string]: string | boolean | string[] } = {
        [DATE_FILTER]: date || '',
        [SUBCATEGORIES_FILTER]: subcategories || [],
        [CATEGORY_FILTER]: category || '',
        online: online || '',
        [FORMAT_FILTER]: format || '',
        [PRICE_FILTER]: price || '',
        [LANGUAGE_FILTER]: language || '',
        [CURRENCY_FILTER]: currency || '',
        [EXPERIENCES_FILTER]: experiences || '',
        hash: hash || '',
        followedOrganizers: followedOrganizers || '',
        organizations: organizations || '',
    };
    !isExperienceFilterExperiment && delete filterObject[EXPERIENCES_FILTER];

    return Object.keys(filterObject)
        .filter((key) => !!filterObject[key] || inactivePillsRenderSet.has(key))
        .sort((a: string, b: string) => {
            const val1 = filterObject[a] ? 1 : 0;
            const val2 = filterObject[b] ? 1 : 0;

            return val2 - val1;
        })
        .map((key: string) => {
            let isActive = !!filterObject[key];
            let filterDisplay;
            //Special cases
            if (key === 'hash') {
                //return special case filtercontainer (?)
                filterDisplay = gettext(
                    'Events similar to %(hashedEventTitle)s',
                    { hashedEventTitle },
                );
            }

            if (key === 'experiences') {
                isActive = (filterObject[key] as string[]).length > 0;

                if (!isActive) {
                    filterDisplay = gettext('Experiences');
                }
            }

            if (key === 'organizations') {
                filterDisplay = gettext('Events that accept this credit');
            }

            if (!filterDisplay) {
                const { values, label } = FILTER_PILLS_DISMISS_MAP[key];
                const appliedFilter = filterObject[key];

                if (isActive) {
                    if (Array.isArray(appliedFilter)) {
                        const appliedFilters = values.find(
                            (filterVal) => filterVal.value === appliedFilter[0],
                        );

                        if (appliedFilters) {
                            filterDisplay = `${appliedFilters?.display}${
                                appliedFilter.length > 1
                                    ? ` + ${appliedFilter.length - 1}`
                                    : ''
                            }`;
                        } else {
                            // Hide the filter if it is an empty array
                            return null;
                        }
                    } else {
                        const filterValue = values.find(
                            (filterVal) =>
                                filterVal.value === filterObject[key],
                        );

                        //If there is no matching value we will default to date
                        filterDisplay = filterValue?.display || date;
                    }
                } else {
                    filterDisplay = label;
                }
            }
            return (
                <span
                    className={`filterPill filter-pill-${key}`}
                    key={key}
                    data-testid={`facet-${key}`}
                >
                    <Tag
                        initialIsActive={false}
                        key={key}
                        onClose={onClose?.bind(null, key)}
                        style={
                            shouldRenderInactivePills
                                ? STYLE_OUTLINED
                                : STYLE_DEFAULT
                        }
                        isClosable={isActive}
                        isStatic={!shouldRenderInactivePills}
                        backgroundColor={
                            shouldRenderInactivePills && isActive
                                ? INTTERACTIVE_TAG_BACKGROUND_COLOR
                                : undefined
                        }
                        onClickStatic={() => onClick?.(key)}
                    >
                        <span className="tag-content">
                            <span className="eds-l-pad-right-1">
                                {filterDisplay}
                                {!isActive && shouldRenderInactivePills ? (
                                    <Icon
                                        type={<ChevronDownChunky />}
                                        color="grey-700"
                                        size="small"
                                    />
                                ) : null}
                            </span>
                        </span>
                    </Tag>
                </span>
            );
        });
};

const getAppliedFilterLabel = (numberActiveFilters: number) => {
    if (numberActiveFilters === 0) {
        return null;
    }
    if (numberActiveFilters === 1) {
        return gettext('%(num)s filter applied', { num: numberActiveFilters });
    }
    return gettext('%(num)s filters applied', { num: numberActiveFilters });
};

export interface FilterHeaderProps {
    handleRemoveFilter?: Function;
    selectedCategory?: string;
    selectedSubcategories?: string[];
    selectedPrice?: string;
    selectedDate?: string;
    selectedFormat?: string;
    selectedLanguage?: string;
    selectedCurrency?: string;
    followedOrganizers?: boolean;
    selectedHash?: string;
    selectedOrganizations?: string[];
    selectedExperiences?: string[];
    isOnline?: boolean;
    handleRemoveOnlineFilter?: Function;
    handleRemoveAllFilters: Function;
    hideActiveFilterLabel?: boolean;
    hashedEventTitle?: string | GenericLazyString;
    openCloseAnimatedDrawer: Function;
    shouldRenderInactivePills?: boolean;
    isBotRequest?: boolean;
    locationSlug?: string;
    isExperienceFilterExperiment?: boolean;
}

export const FilterHeaderPills = (props: FilterHeaderProps) => {
    const {
        selectedDate,
        selectedCategory,
        selectedSubcategories,
        selectedPrice,
        selectedFormat,
        selectedLanguage,
        selectedCurrency,
        selectedOrganizations,
        followedOrganizers,
        selectedExperiences,
        selectedHash,
        isOnline,
        handleRemoveOnlineFilter,
        handleRemoveFilter,
        handleRemoveAllFilters,
        hideActiveFilterLabel,
        openCloseAnimatedDrawer,
        hashedEventTitle,
        shouldRenderInactivePills,
        isExperienceFilterExperiment,
    } = props;

    const filters = {
        date: selectedDate,
        format: selectedFormat,
        price: selectedPrice,
        category: selectedCategory,
        subcategories: selectedSubcategories,
        language: selectedLanguage,
        currency: selectedCurrency,
        online: isOnline,
        followedOrganizers: followedOrganizers,
        hash: selectedHash,
        organizations: selectedOrganizations,
        experiences: selectedExperiences,
    };

    function onRemoveFilter(filterType: string) {
        const followedOrganizers = 'followedOrganizers';
        const organizations = 'organizations';

        if (filterType === followedOrganizers) {
            handleRemoveFilter?.({ special: true });
        } else if (filterType === organizations) {
            handleRemoveFilter?.({ organizationsOr: true });
        } else if (filterType === 'online') {
            handleRemoveOnlineFilter?.();
        } else {
            handleRemoveFilter?.({ [filterType]: true });
        }
    }

    function handleClearAllFilters() {
        handleRemoveAllFilters();

        if (isOnline) {
            handleRemoveOnlineFilter?.();
        }
    }

    const appliedFiltersAsFacets = getRenderedFilters({
        ...filters,
        hashedEventTitle,
        shouldRenderInactivePills,
        inactivePillsRenderSet: shouldRenderInactivePills
            ? ALWAYS_RENDER_FILTER_SET
            : new Set(),
        onClose: onRemoveFilter,
        onClick: (key) => {
            props.openCloseAnimatedDrawer(true, key);
        },
        isExperienceFilterExperiment,
    });

    const numberActiveFilters = Object.values(filters)
        .filter((val) => (Array.isArray(val) ? val.length : Boolean(val)))
        .reduce((acc, val) => acc + (Array.isArray(val) ? val.length : 1), 0);
    const hasActiveFilters = numberActiveFilters > 0;
    const numberActiveFiltersLabel = getAppliedFilterLabel(numberActiveFilters);

    if (!shouldRenderInactivePills && !hasActiveFilters) {
        return null;
    }

    return (
        <div
            className={classNames('filter-header', {
                'filter-header__active': hasActiveFilters,
                'filter-header__inactive': !hasActiveFilters,
                'filter-header__render-inactive-pills':
                    shouldRenderInactivePills,
            })}
        >
            {!hideActiveFilterLabel ? (
                <FilterHeaderPillsToggle
                    numberActiveFiltersLabel={numberActiveFiltersLabel}
                    numberActiveFilters={numberActiveFilters}
                    openCloseAnimatedDrawer={openCloseAnimatedDrawer}
                />
            ) : null}
            <div className="filterPills">{[...appliedFiltersAsFacets]}</div>
            {numberActiveFilters > 0 && (
                <div className="clearAll">
                    <MarmaladeButton
                        onClick={() => handleClearAllFilters()}
                        variant="ghost"
                    >
                        {gettext('Clear All')}
                    </MarmaladeButton>
                </div>
            )}
        </div>
    );
};

export default FilterHeaderPills;
