import type { PaginationData, WithRouterProps } from '@eventbrite/search-utils';
import {
    connectSearchFooter,
    getPaginationData,
    SearchFooter,
} from '@eventbrite/search-utils';
import loadable from '@loadable/component';
import React from 'react';
import { withRouter } from '../../utils/router';

const SearchFooterLegacy = loadable(() => import('./components'), {
    resolveComponent: (components: any) => components.SearchFooterLegacy,
});

export interface SearchFooterProps extends WithRouterProps {
    pagination: PaginationData;
}

export type SearchFooterReduxProps = {
    isBotRequest: boolean;
    prevPageUrl: string;
    nextPageUrl: string;
    firstPageUrl: string;
    lastPageUrl: string;
    handleGetPage: Function;
};

/**
 * If the request is from a bot, render the legacy footer (to show more page number links)
 */
const SearchFooterWrapper = (
    props: SearchFooterProps & SearchFooterReduxProps,
) => {
    const { isBotRequest, pagination } = props;
    const paginationData = getPaginationData(pagination);

    return isBotRequest ? (
        <SearchFooterLegacy {...props} {...paginationData} />
    ) : (
        <SearchFooter {...props} {...paginationData} />
    );
};

export const SearchFooterContainer = withRouter<SearchFooterProps>(
    connectSearchFooter(SearchFooterWrapper),
);
