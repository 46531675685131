import { track } from '@eventbrite/datalayer-library';
import { gettext } from '@eventbrite/i18n';

import { VideoAdsEventbriteLogo, VideoAdsUpsell } from '@eventbrite/ads';
import { Button, Typography } from '@eventbrite/marmalade';
import { logEvent } from '@eventbrite/statsig';
import classnames from 'classnames';
import React from 'react';
import { CloseableDialog } from '../../../../components/CloseableDialog';
import decorativeVideoBackground from './assets/bg-pattern.webp';
import dottedLineBackground from './assets/dotted-line.webp';
import styles from './SearchVideoAdsUpsell.module.scss';
import { STATSIG_SEARCH_VIDEO_ADS_UPSELL } from './SearchVideoAdsUpsellUtils';

const currentComponent = 'Post-Search Ads Video';
const HEAP_CLICK_ADS_LINK = `${currentComponent} - Click - Ads link`;

export type SearchVideoAdsUpsellProps = React.PropsWithoutRef<{
    className?: string;
}>;

const containerBackgroundStyles = {
    background: `url(${dottedLineBackground})`,
    backgroundSize: 'cover',
    backgroundColor: '#f8f7fa',
};

const videoBackgroundStyles = {
    background: `url(${decorativeVideoBackground})`,
    backgroundSize: 'cover',
};

const videoTrackingMetadata = {
    component: currentComponent,
    interface: 'Search - Promoted',
};

export function SearchVideoAdsUpsell({
    className,
    ...props
}: SearchVideoAdsUpsellProps) {
    const trackUpsellLink = () => {
        logEvent(STATSIG_SEARCH_VIDEO_ADS_UPSELL);
        track({
            eventName: HEAP_CLICK_ADS_LINK,
            eventData: {
                interface: 'Search - Promoted',
            },
        });
    };

    return (
        <CloseableDialog
            as="aside"
            className={classnames(className, styles.root)}
            iconClassName={styles.closeIcon}
            style={containerBackgroundStyles}
            {...props}
        >
            <section className={styles.info}>
                <VideoAdsEventbriteLogo
                    data-testid="video-ads-eventbrite-logo"
                    onLinkClick={trackUpsellLink}
                />
                <Typography
                    className={styles.callout}
                    variant="heading-md"
                    color="black"
                    as="h3"
                >
                    <span
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: gettext(
                                'Grow your community and revenue<strong>—all at the same time</strong>',
                            ).toString(),
                        }}
                    ></span>
                </Typography>
                <a
                    href="/organizations/marketing/eventbrite-ads"
                    target="_blank"
                    rel="nofollow noopener"
                    onClick={trackUpsellLink}
                >
                    <Button variant="primary">{gettext('Get started')}</Button>
                </a>
            </section>
            <VideoAdsUpsell
                className={styles.video}
                data-testid="video-ads-upsell"
                trackingMetadata={videoTrackingMetadata}
            />
            <div
                className={styles.videoDecorativeBackground}
                style={videoBackgroundStyles}
            />
        </CloseableDialog>
    );
}
