import { BaseDiscoverState } from '@eventbrite/discover-utils';
import { updateSearchCriteria } from '../..';
import { GA_CHANGE_PAGE_ACTION } from '../../../constants';

export const handleGetPage =
    (futurePage: number) => (dispatch: Function, getState: Function) => {
        const state: BaseDiscoverState = getState();
        const {
            search: { eventSearch },
        } = state;

        const newEventSearch = {
            ...eventSearch,
            page: futurePage,
        };

        const searchActionToLog = GA_CHANGE_PAGE_ACTION;

        dispatch(updateSearchCriteria(newEventSearch, searchActionToLog));
    };
