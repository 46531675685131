import { PromotedActionsMenuEventCardProps } from '@eventbrite/ads-event-card-extra';
import { FormattedEvent } from '@eventbrite/event-renderer';
import { $FixMe } from '@eventbrite/ts-utils';
import React, { FunctionComponent, useContext } from 'react';
import { connect } from 'react-redux';
import { trackEventClickInternal as trackEventClickInternalAction } from '../../../../../../redux/actions/analytics';
import { setHoverEventTarget as setHoverEventTargetAction } from '../../../../../../redux/actions/ui';
import { TrackingContext } from '../../../../../../trackingContext';
import { useSearchUpsell } from '../../../../components/SearchVideoAdsUpsell';
import { useMapExperiment } from '../../../../hooks';
import { useInitialEventsExpansionQueryState } from '../../../../SearchPageDataExpansions';
import { SearchResultsPanelAdsUpsell } from '../SearchResultPanelAdsUpsell';
import { SearchResultPanelContentEventCard } from '../SearchResultPanelContentEventCard';
import './SearchResultPanelContentEventCardList.module.scss';
import styles from './SearchResultPanelContentEventCardList.module.scss';

type SearchResultsPanelContentEventCardListProps = Pick<
    PromotedActionsMenuEventCardProps,
    'getTrackingContext' | 'onRemoveEvent'
> & {
    events: FormattedEvent[];
    locale: string;
};

type SearchResultsPanelContentEventCardListReduxProps = {
    isAuthenticated?: boolean;
    shouldShowDebugOutput?: boolean;
    setHoverEventTarget: Function;
    focusedEvents: string[];
    raisedEvents: string[];
    page: number;
    // Tracking functions
    onTrackEventClickInternal: Function;
};

export const SearchResultPanelContentEventCardListInternal = ({
    events = [],
    isAuthenticated = false,
    onTrackEventClickInternal,
    shouldShowDebugOutput,
    setHoverEventTarget,
    focusedEvents,
    raisedEvents,
    page,
    getTrackingContext,
    onRemoveEvent,
}: SearchResultsPanelContentEventCardListProps &
    SearchResultsPanelContentEventCardListReduxProps) => {
    const { locale } = useContext(TrackingContext);
    const expansionStatus = useInitialEventsExpansionQueryState();
    const isMapExperimentEnabled = useMapExperiment();

    const eventCards = events.map((event: FormattedEvent, index: number) => (
        <li key={`${event.id}-${event.isPromoted}`}>
            <div className={styles.map_experiment_event_card}>
                {!isMapExperimentEnabled ? (
                    <SearchResultPanelContentEventCard
                        isAuthenticated={isAuthenticated}
                        onTrackEventClickInternal={onTrackEventClickInternal}
                        locale={locale}
                        shouldShowDebugOutput={shouldShowDebugOutput}
                        setHoverEventTarget={setHoverEventTarget}
                        scrollId={focusedEvents[0]}
                        isExpansionsLoading={
                            expansionStatus === undefined ||
                            expansionStatus === 'loading'
                        }
                        getTrackingContext={getTrackingContext}
                        position={index + 1}
                        isLazyImage={index > 3}
                        isRaised={
                            focusedEvents.includes(event.id) ||
                            raisedEvents.includes(event.id)
                        }
                        event={event}
                        onRemoveEvent={onRemoveEvent}
                        page={page}
                    />
                ) : (
                    <SearchResultPanelContentEventCard
                        isAuthenticated={isAuthenticated}
                        onTrackEventClickInternal={onTrackEventClickInternal}
                        locale={locale}
                        shouldShowDebugOutput={shouldShowDebugOutput}
                        setHoverEventTarget={setHoverEventTarget}
                        scrollId={focusedEvents[0]}
                        isExpansionsLoading={
                            expansionStatus === undefined ||
                            expansionStatus === 'loading'
                        }
                        getTrackingContext={getTrackingContext}
                        position={index + 1}
                        isLazyImage={index > 3}
                        isRaised={
                            focusedEvents.includes(event.id) ||
                            raisedEvents.includes(event.id)
                        }
                        event={event}
                        onRemoveEvent={onRemoveEvent}
                        page={page}
                        forceVariant={'mobile'}
                    />
                )}
            </div>
        </li>
    ));

    const eventCardsWithUpsells = useEventCardsWithUpsells(eventCards, events);

    return (
        <ul
            className={
                isMapExperimentEnabled
                    ? styles.grid_eventList
                    : styles.eventList
            }
        >
            {eventCardsWithUpsells}
        </ul>
    );
};

function useEventCardsWithUpsells(
    eventCards: JSX.Element[],
    events: FormattedEvent[],
) {
    const showUpsell = useSearchUpsell(events);

    if (!showUpsell) return eventCards;

    // For now, promoted events are always the first N events
    const promotedEvents = events.filter((event) => event.isPromoted);
    const lastPromotedIndex = promotedEvents.length;
    return [
        eventCards.slice(0, lastPromotedIndex),
        <li key="promoted-events-upsell">
            <SearchResultsPanelAdsUpsell data-testid="promoted-events-upsell" />
        </li>,
        eventCards.slice(lastPromotedIndex),
    ];
}

export const _mapStateToProps = (
    state: any,
    ownProps: SearchResultsPanelContentEventCardListProps,
) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        shouldShowDebugOutput: state.admin.shouldShowDebugOutput,
        focusedEvents: state.ui.focusedEventCards,
        raisedEvents: state.ui.raisedEventCards,
        page: state.search.eventSearch.page,
        ...ownProps,
    };
};

const _mapDispatchToProps = (dispatch: Function) => ({
    setHoverEventTarget: (...args: [$FixMe]) =>
        dispatch(setHoverEventTargetAction(...args)),
    onTrackEventClickInternal: (...args: [$FixMe]) =>
        dispatch(trackEventClickInternalAction(...args)),
});

export const SearchResultPanelContentEventCardList: FunctionComponent<SearchResultsPanelContentEventCardListProps> =
    connect(
        _mapStateToProps,
        _mapDispatchToProps,
    )(SearchResultPanelContentEventCardListInternal);
