import {
    EventSearch,
    ExperienceFiltersExperiment,
    ExperienceFiltersExperimentValues,
} from '@eventbrite/discover-utils';
import { withOverlayControls } from '@eventbrite/eds-structure';
import { HAS_WINDOW } from '@eventbrite/feature-detection';
import { gettext } from '@eventbrite/i18n';
import loadable from '@loadable/component';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { usePrevious } from 'react-use';
import { LOCATION_DIALOG_CONTENT } from '../../../../constants';
import { useServerEnrolledStatsigExperiment } from '../../../../context/experiments';
import {
    getSearchHeaderTitle,
    getSelectedDate,
} from '../../../../redux/selectors/search';
import { DiscoverState } from '../../../../types';
import ExperienceFiltersContainer from './components/ExperienceFilters';
import './SearchHeaderContainer.scss';

const AdminSearchSubHeaderContainer = loadable(
    () => import('../AdminSearchSubHeader'),
    {
        resolveComponent: (components: {
            AdminSearchSubHeaderContainer: React.ReactElement;
        }) => components.AdminSearchSubHeaderContainer,
    },
);
interface SearchHeaderProps {
    /*
     * From withOverlayControls HoC
     */
    showOverlay: Function;
    searchHeaderTitle?: string;
    locationDenied?: boolean;
    eventSearch: EventSearch;
    locale: string;
}

export const SearchHeader: React.FunctionComponent<SearchHeaderProps> = (
    props,
) => {
    const {
        searchHeaderTitle,
        locationDenied,
        showOverlay,
        eventSearch,
        locale,
    } = props;

    const shouldExperienceFiltersBeEnabled = locale === 'en_US';
    const ExperienceFiltersExperimentVariant =
        useServerEnrolledStatsigExperiment<ExperienceFiltersExperimentValues>({
            name: ExperienceFiltersExperiment.name,
            paramName: ExperienceFiltersExperiment.paramName,
            defaultValue: ExperienceFiltersExperiment.values.control,
            enabled: shouldExperienceFiltersBeEnabled,
        }).variant;

    const prevLocationDenied = usePrevious(locationDenied);

    useEffect(() => {
        if (locationDenied && locationDenied !== prevLocationDenied) {
            showOverlay('dialog', LOCATION_DIALOG_CONTENT);
        }
    }, [locationDenied, showOverlay, prevLocationDenied]);

    const hasEventQuery = eventSearch?.q?.length;

    return (
        <header className="search-header">
            {hasEventQuery ? (
                <div className="search-header__result-header">
                    <h1 className="eds-text-hm eds-text-color--grey-700 search-header__bot-title-display">
                        {searchHeaderTitle}
                    </h1>
                </div>
            ) : (
                <div className="search-header__result-header">
                    <h1>{searchHeaderTitle}</h1>
                    <p>
                        {gettext(
                            'Search for something you love or check out popular events in your area.',
                        )}
                    </p>
                </div>
            )}
            {HAS_WINDOW && window.location.hash === '#debug' && (
                <AdminSearchSubHeaderContainer />
            )}
            {ExperienceFiltersExperimentVariant ===
                ExperienceFiltersExperimentValues.test && (
                <ExperienceFiltersContainer />
            )}
        </header>
    );
};

const _mapStateToProps = (state: DiscoverState) => ({
    locale: state.app.locale,
    eventSearch: state.search.eventSearch,
    selectedDate: getSelectedDate(
        state.search.eventSearch.dates,
        state.search.eventSearch.dateRange,
    ),
    searchHeaderTitle: getSearchHeaderTitle(state),
    locationDenied: state.location?.locationDenied,
    currentLocationCoordinates: state.location?.currentLocationCoordinates,
});

export const SearchHeaderContainer = withOverlayControls<any>(
    connect(_mapStateToProps)(SearchHeader),
);
