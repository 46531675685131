import {
    BUDGET_FRIENDLY_EXPERIENCE,
    DATE_NIGHT_EXPERIENCE,
    EXPERIENCES_MAPPING,
    GREAT_FOR_GROUPS_EXPERIENCE,
    KID_FRIENDLY_EXPERIENCE,
    NIGHTLIFE_EXPERIENCE,
} from '@eventbrite/discover-utils';
import { GenericLazyString } from '@eventbrite/i18n';

import budgetFriendlyImage from './images/budget-friendly.webp';
import dateNightImage from './images/date-night.webp';
import greatForGroupsImage from './images/great-for-groups.webp';

export interface ExperienceProps {
    name: GenericLazyString;
    value: string;
    imageUrl: string;
    hasEBCategory: boolean;
}

// some of the experiences have images already associated with eventbrite categories.
// The rest we need to import images for.
export const experiences: ExperienceProps[] = [
    {
        name: EXPERIENCES_MAPPING[NIGHTLIFE_EXPERIENCE],
        value: NIGHTLIFE_EXPERIENCE,
        imageUrl: 'Destination/nightlife',
        hasEBCategory: true,
    },
    {
        name: EXPERIENCES_MAPPING[KID_FRIENDLY_EXPERIENCE],
        value: KID_FRIENDLY_EXPERIENCE,
        imageUrl: 'EventbriteCategory/115',
        hasEBCategory: true,
    },
    {
        name: EXPERIENCES_MAPPING[GREAT_FOR_GROUPS_EXPERIENCE],
        value: GREAT_FOR_GROUPS_EXPERIENCE,
        imageUrl: greatForGroupsImage,
        hasEBCategory: false,
    },
    {
        name: EXPERIENCES_MAPPING[DATE_NIGHT_EXPERIENCE],
        value: DATE_NIGHT_EXPERIENCE,
        imageUrl: dateNightImage,
        hasEBCategory: false,
    },
    {
        name: EXPERIENCES_MAPPING[BUDGET_FRIENDLY_EXPERIENCE],
        value: BUDGET_FRIENDLY_EXPERIENCE,
        imageUrl: budgetFriendlyImage,
        hasEBCategory: false,
    },
];
