import {
    EventInteraction,
    useExternalTabTracker,
    useTracking,
} from '@eventbrite/ads';
import { FormattedEvent } from '@eventbrite/event-renderer';
import { $FixMe } from '@eventbrite/ts-utils';
import loadable from '@loadable/component';
import { isEmpty } from 'lodash';
import React, { FunctionComponent, useContext } from 'react';
import { connect } from 'react-redux';
import { useEnvContext } from '../../../../context';
import { TrackingContext } from '../../../../trackingContext';
import { useRemovePromotedSearchEvent } from '../../SearchPageData';
import {
    aggregateTrackingContext,
    SEARCH_CONTENT_SUBINTERFACE,
} from '../../utils/tracking';
import { SearchResultPanelContentEventCardList } from './components/SearchResultPanelContentEventCardList';
import styles from './SearchResultPanelContent.module.scss';

const LoadingCards = loadable(
    () => import('../../../../components/LoadingCards'),
);

type SearchResultsProps = {
    events: FormattedEvent[];
    isLoading: boolean;
};

type SearchResultsReduxProps = {
    searchId: string;
    eventSearch: $FixMe;
};

export const SearchResultPanelContentInternal = ({
    events = [],
    eventSearch,
    searchId,
    isLoading,
}: SearchResultsProps & SearchResultsReduxProps) => {
    const { user, request } = useEnvContext();
    const { locale } = useContext(TrackingContext);
    const hasEventResults = !isEmpty(events);
    const { removeEvent: onRemoveEvent } =
        useRemovePromotedSearchEvent(eventSearch);
    const getTrackingContext = ({ id, event }: EventInteraction) =>
        aggregateTrackingContext({
            id,
            event,
            events,
            eventSearch,
            searchId,
            userId: user?.publicId,
            locale,
            sessionId: request?.session_id,
            guestId: user?.guestId,
            subInterface: SEARCH_CONTENT_SUBINTERFACE,
        });

    useExternalTabTracker(searchId, [eventSearch]);

    const { ref } = useTracking<HTMLDivElement>(getTrackingContext);

    if (!isLoading && !hasEventResults) {
        return null;
    }

    if (isLoading) {
        return (
            <section ref={ref}>
                <div className={styles.loadingDesktop}>
                    <LoadingCards
                        isFlipped={false}
                        cardCount={10}
                        style={'list-responsive'}
                    />
                </div>
                <div className={styles.loadingMobile}>
                    <LoadingCards isFlipped={false} style="grid" />
                </div>
            </section>
        );
    }

    return (
        <section ref={ref}>
            <SearchResultPanelContentEventCardList
                events={events}
                locale={locale}
                getTrackingContext={getTrackingContext}
                onRemoveEvent={onRemoveEvent}
            />
        </section>
    );
};

export const _mapStateToProps = (state: any, ownProps: SearchResultsProps) => {
    return {
        eventSearch: state.search.eventSearch,
        searchId: state.search.searchId,
        ...ownProps,
    };
};

export const SearchResultPanelContent: FunctionComponent<SearchResultsProps> =
    connect(_mapStateToProps)(SearchResultPanelContentInternal);
