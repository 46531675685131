import { isSubCategory } from '@eventbrite/categories';
import {
    CATEGORY_TAG_TYPE,
    EventSearch,
    FORMAT_TAG_TYPE,
} from '@eventbrite/discover-utils';
import uniq from 'lodash/uniq';
import { findTagByType } from '../../../utils';

/**
 * Counts the currently selected filters
 * @param  {Object} state Current application state
 * @return {Nubmer}       Number of selected filters
 */
export const getSelectedFilterCount = (state: {
    search: { eventSearch: EventSearch };
}) => {
    const {
        search: { eventSearch },
    } = state;

    return (
        // Dates will be a string if no date is selected, else array
        // Dates always has at least "current_future" already, so don't count it
        (Array.isArray(eventSearch.dates) ? eventSearch.dates.length - 1 : 0) +
        // A custom date would show up in a different way
        (eventSearch.dateRange.to && eventSearch.dateRange.from ? 1 : 0) +
        // Categories and formats are both tags, and you can only select one of each right now
        (eventSearch?.tags || []).length +
        // Price is either undefined, paid or free
        (eventSearch.price !== undefined ? 1 : 0) +
        // Language and currency are both only able to select one
        eventSearch.currencies.length +
        (eventSearch.languages.length >= 1 ? 1 : 0)
    );
};

export const getSelectedCategory = (tags?: string[]) =>
    findTagByType(CATEGORY_TAG_TYPE, tags);

export const getSelectedSubcategories = (tags: string[] = []) => {
    const subcategories = uniq(tags.filter((tag) => isSubCategory(tag))).sort();
    return subcategories.length ? subcategories : undefined;
};

export const getSelectedFormat = (tags?: string[]) =>
    findTagByType(FORMAT_TAG_TYPE, tags);
