import {
    EventSearch,
    ExperienceFiltersExperiment,
    ExperienceFiltersExperimentValues,
} from '@eventbrite/discover-utils';
import {
    FOLLOWED_ORGS_FILTER_VALUE,
    getSelectedFormat,
    openCloseAnimatedDrawer as openCloseAnimatedDrawerAction,
} from '@eventbrite/search-utils';
import { $FixMe } from '@eventbrite/ts-utils';
import loadable from '@loadable/component';
import React from 'react';
import { connect } from 'react-redux';
import {
    handleRemoveAllFilters as handleRemoveAllFiltersAction,
    handleRemoveFilter as handleRemoveFilterAction,
    handleRemoveOnlineFilter as handleRemoveOnlineFilterAction,
} from '../../../../redux/actions/search';
import { getSelectedDate } from '../../../../redux/selectors/search';
import {
    FilterHeaderPills,
    FilterHeaderProps,
} from '../../components/FilterHeader/FilterHeaderPills';

const BotFilters = loadable(() => import('@eventbrite/search-utils'), {
    resolveComponent: (component: { BotFilters: React.ReactNode }) =>
        component.BotFilters,
});

import { gettext } from '@eventbrite/i18n';
import {
    getSelectedCategory,
    getSelectedSubcategories,
} from '@eventbrite/search-utils';
import { useServerEnrolledStatsigExperiment } from '../../../../context/experiments';
import { DiscoverState } from '../../../../types';
import { useCurrentSearchOrganicEvents } from '../../SearchPageData';
import './FilterHeaderContainer.scss';

export type FilterHeaderContainerProps = Omit<
    FilterHeaderProps,
    'hashedEventTitle'
> & {
    eventSearch: EventSearch;
    isAuthenticated: boolean;
    locale: string;
};

export const FilterHeader = (props: FilterHeaderContainerProps) => {
    const {
        handleRemoveFilter,
        selectedCategory,
        selectedSubcategories,
        selectedPrice,
        selectedDate,
        selectedFormat,
        selectedLanguage,
        selectedCurrency,
        selectedOrganizations,
        followedOrganizers,
        selectedExperiences,
        isOnline,
        handleRemoveOnlineFilter,
        handleRemoveAllFilters,
        selectedHash,
        eventSearch,
        hideActiveFilterLabel,
        openCloseAnimatedDrawer,
        isBotRequest,
        locationSlug,
        isAuthenticated,
        locale,
    } = props;

    const hashedEventTitle = useHashedEventTitle(eventSearch);

    const filterHeaderProps: FilterHeaderProps = {
        handleRemoveFilter: handleRemoveFilter,
        selectedPrice: selectedPrice,
        selectedDate: selectedDate,
        selectedFormat: selectedFormat,
        selectedLanguage: selectedLanguage,
        selectedCurrency: selectedCurrency,
        followedOrganizers: followedOrganizers,
        selectedExperiences: selectedExperiences,
        isOnline: isOnline,
        selectedCategory: selectedCategory,
        selectedSubcategories,
        handleRemoveOnlineFilter: handleRemoveOnlineFilter,
        selectedOrganizations: selectedOrganizations,
        handleRemoveAllFilters: handleRemoveAllFilters,
        hashedEventTitle,
        selectedHash: selectedHash,
        hideActiveFilterLabel: hideActiveFilterLabel,
        openCloseAnimatedDrawer: openCloseAnimatedDrawer,
    };

    const shouldExperienceFiltersBeEnabled = locale === 'en_US';
    const ExperienceFiltersExperimentVariant =
        useServerEnrolledStatsigExperiment<ExperienceFiltersExperimentValues>({
            name: ExperienceFiltersExperiment.name,
            paramName: ExperienceFiltersExperiment.paramName,
            defaultValue: ExperienceFiltersExperiment.values.control,
            enabled: shouldExperienceFiltersBeEnabled,
        }).variant;
    const isExperienceFilterExperiment =
        ExperienceFiltersExperimentVariant === 'show';

    if (isBotRequest) {
        return (
            <span className={'filter-header-container__mobile'}>
                <BotFilters slug={locationSlug} />
            </span>
        );
    } else {
        return (
            <>
                <span
                    className={`filter-header-container filter-header-container__mobile ${
                        isAuthenticated
                            ? ''
                            : 'filter-header-container__unauthenticated'
                    }`}
                >
                    <FilterHeaderPills
                        {...filterHeaderProps}
                        shouldRenderInactivePills={
                            props.shouldRenderInactivePills !== undefined
                                ? props.shouldRenderInactivePills
                                : true
                        }
                        isExperienceFilterExperiment={
                            isExperienceFilterExperiment
                        }
                    />
                </span>

                <span className="filter-header-container filter-header-container__desktop">
                    <FilterHeaderPills
                        {...filterHeaderProps}
                        shouldRenderInactivePills={
                            props.shouldRenderInactivePills !== undefined
                                ? props.shouldRenderInactivePills
                                : false
                        }
                        isExperienceFilterExperiment={
                            isExperienceFilterExperiment
                        }
                    />
                </span>
            </>
        );
    }
};

const _mapStateToProps = (state: DiscoverState) => ({
    locale: state.app.locale,
    selectedPrice: state.search.eventSearch.price,
    isBotRequest: state.app.isBotRequest,
    locationSlug: state.location.slug,
    selectedCategory: getSelectedCategory(state.search.eventSearch.tags),
    selectedSubcategories: getSelectedSubcategories(
        state.search.eventSearch.tags,
    ),
    selectedFormat: getSelectedFormat(state.search.eventSearch.tags),
    selectedDate: getSelectedDate(
        state.search.eventSearch.dates,
        state.search.eventSearch.dateRange,
    ),
    selectedCurrency:
        state.search.eventSearch.currencies.length > 0
            ? state.search.eventSearch.currencies[0]
            : '',
    selectedLanguage:
        state.search.eventSearch.languages.length > 0
            ? state.search.eventSearch.languages
            : '',
    followedOrganizers: state.search.eventSearch?.special?.includes(
        FOLLOWED_ORGS_FILTER_VALUE,
    ),
    selectedExperiences: state.search.eventSearch.experiences,
    selectedOrganizations: state.search.eventSearch.organizationsOr?.length
        ? state.search.eventSearch.organizationsOr
        : undefined,
    isOnline: state.search.eventSearch.onlineEventsOnly,
    selectedHash: state.search.eventSearch.hash,
    eventSearch: state.search.eventSearch,
    isAuthenticated: state.auth.isAuthenticated,
});

function useHashedEventTitle(eventSearch: EventSearch) {
    const currentEvents = useCurrentSearchOrganicEvents(eventSearch);
    if (!eventSearch.hash) {
        return '';
    }

    return currentEvents[0]?.name || gettext('this event');
}

const _mapDispatchToProps = (dispatch: Function) => ({
    handleRemoveFilter: (...args: [$FixMe]) =>
        dispatch(handleRemoveFilterAction(...args)),
    handleRemoveOnlineFilter: () => dispatch(handleRemoveOnlineFilterAction()),
    handleRemoveAllFilters: () => dispatch(handleRemoveAllFiltersAction()),
    openCloseAnimatedDrawer: (...args: [$FixMe]) =>
        dispatch(openCloseAnimatedDrawerAction(...args)),
});

export const FilterHeaderContainer = connect(
    _mapStateToProps,
    _mapDispatchToProps,
)(FilterHeader);
