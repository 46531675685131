import * as React from 'react';

const PlayFillSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="play-fill_svg__eds-icon--play-fill_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="play-fill_svg__eds-icon--play-fill_base"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#010101"
            d="M7 6v12l12-6z"
        />
    </svg>
);

PlayFillSvg.displayName = 'PlayFillSvg';
export default PlayFillSvg;
