import { LogoEBrand } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import { Icon, Typography } from '@eventbrite/marmalade';
import classnames from 'classnames';
import React from 'react';
import styles from './VideoAdsEventbriteLogo.module.scss';

export type VideoAdsEventbriteLogoProps = React.PropsWithoutRef<{
    className?: string;
    onLinkClick?: () => void;
}>;

export function VideoAdsEventbriteLogo({
    className,
    onLinkClick,
    ...props
}: VideoAdsEventbriteLogoProps) {
    return (
        <div className={classnames(className, styles.root)} {...props}>
            <Icon className={styles.logo} data-testid="eventbrite-logo">
                <LogoEBrand />
            </Icon>
            <Typography variant="body-sm-bold">
                <span
                    // Since we cannot interpolate a react component into our version of i18next we record heap events for the whole string
                    onClick={onLinkClick}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: gettext(
                            "Brought to you by <a href=%(href)s target='_blank' rel='nofollow noopener'><strong>Eventbrite Ads</strong></a>",
                            {
                                href: '/organizations/marketing/eventbrite-ads',
                            },
                        ).toString(),
                    }}
                />
            </Typography>
        </div>
    );
}
