/* eslint-disable jsx-a11y/label-has-associated-control */
import { css } from 'emotion';
import React from 'react';

export const SpinnerWheel = () => {
    return (
        <div data-testid="loading-spinner-wheel" className="'eds-align--center">
            <svg
                viewBox="0 0 100 100"
                className={css({
                    '@keyframes rotate': {
                        '100%': {
                            transform: 'rotate(360deg)',
                        },
                    },
                    animationDelay: '0s',
                    animationDirection: 'normal',
                    animationDuration: '0.8s',
                    animationFillMode: 'forwards',
                    animationIterationCount: 'infinite',
                    animationName: 'rotate',
                    animationPlayState: 'running',
                    animationTimingFunction: 'linear',
                    width: '96px',
                    height: '96px',
                })}
            >
                <defs>
                    <linearGradient id="stroke-large-chunky-gradient-indeterminate">
                        <stop offset="0%" stopColor="#4BE1A0" />
                        <stop offset="50%" stopColor="#4BE1A0" />
                        <stop
                            offset="100%"
                            stopColor="#4BE1A0"
                            stopOpacity="0"
                        />
                    </linearGradient>
                </defs>
                <path
                    d="M93.5,50C93.5,74,74,93.5,50,93.5S6.5,74,6.5,50S26,6.5,50,6.5"
                    stroke="url(#stroke-large-chunky-gradient-indeterminate)"
                    strokeWidth="4"
                    strokeLinecap="round"
                    shapeRendering="geometricPrecision"
                    fill="none"
                />
            </svg>
        </div>
    );
};
