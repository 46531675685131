import React, { useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';

export type RenderOnViewportProps = React.PropsWithChildren<{
    options?: IntersectionObserverInit;
}>;

export function RenderOnViewport({
    children,
    options = {},
    ...props
}: RenderOnViewportProps) {
    const ref = useRef(null);
    const [isInViewport, setViewportVisibility] = useState(false);
    const intersection = useIntersection(ref, options);

    useEffect(() => {
        if (!isInViewport && intersection?.isIntersecting) {
            setViewportVisibility(true);
        }
    }, [isInViewport, intersection?.isIntersecting]);

    return !isInViewport ? <div ref={ref} {...props} /> : <>{children}</>;
}
