import isArray from 'lodash/isArray';
import last from 'lodash/last';
import { DiscoverState } from '../../types/index';
import {
    formatDateRangeStringForDisplay,
    getSearchEventHeaderTitle,
} from '../../utils/general';

//If no date filter is applied the value will
//default to "current_future", if any filters
//are applied we will receive an array with
//both 'current_future' and whatever other
//filters. But that is only instance of more
//than one filter so we can feel safe returning
//the second option.
export const getSelectedDate = (
    date?: string[],
    { from: startDate, to: endDate }: { from?: string; to?: string } = {},
) => {
    let dateToReturn;

    if (isArray(date)) {
        dateToReturn = last(date) === 'current_future' ? undefined : last(date);
    }

    if (startDate && endDate) {
        dateToReturn = formatDateRangeStringForDisplay({ startDate, endDate });
    }

    return dateToReturn;
};

export const getSearchHeaderTitle = (
    state: Pick<DiscoverState, 'botH1' | 'location' | 'search'>,
) => {
    if (state.botH1) {
        return state.botH1;
    }

    return getSearchEventHeaderTitle(
        {
            currentPlace: state.location?.currentPlace || '',
            currentPlaceParent: state.location?.currentPlaceParent || '',
            placeType: state.location?.placeType,
            isOnline: state.location?.isOnline,
        },
        {
            eventSearch: state.search.eventSearch,
        },
    );
};
