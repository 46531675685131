import { Button, TYPE_LINK } from '@eventbrite/eds-button';
import { setWindowLocation } from '@eventbrite/http';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import React from 'react';
import { Link } from 'react-router-dom';

interface SeeMoreButtonProps {
    /**
     * Title of parent bucket
     */
    title: string | GenericLazyString;
    /**
     * The url the user should be directed to
     */
    linkUrl: string;
    /**
     * The text the button should display
     */
    seeMoreText?: string;
    /**
     * Tracking callback for button clicks
     */
    redirectOnClick?: boolean;
    /**
     * boolean to determine whether we should redirect when link/button is clicked
     */
    size?: string;
    /**
     * If the component should render a link
     */
    enableClientSideLinking?: boolean;
}

export const SeeMoreButton = ({
    title,
    linkUrl,
    seeMoreText,
    redirectOnClick,
    enableClientSideLinking,
    size = 'responsive',
}: SeeMoreButtonProps) => {
    const ariaLabel = seeMoreText
        ? seeMoreText
        : gettext('See more %(title)s', { title });
    const buttonLabel = seeMoreText
        ? seeMoreText
        : gettext('Explore more events');

    const handleSeeMoreClick = () => {
        if (redirectOnClick) {
            setWindowLocation(linkUrl);
        }
    };

    if (!linkUrl) {
        return null;
    }
    return (
        <>
            {enableClientSideLinking ? (
                <Link
                    aria-label={`${ariaLabel.toString()}`}
                    to={linkUrl}
                    onClick={handleSeeMoreClick}
                >
                    {buttonLabel}
                </Link>
            ) : (
                <Button
                    type={TYPE_LINK}
                    href={linkUrl}
                    onClick={handleSeeMoreClick}
                    aria-label={ariaLabel}
                    size={size}
                >
                    {buttonLabel}
                </Button>
            )}
        </>
    );
};

export default SeeMoreButton;
