import { useEffect, useMemo } from 'react';

type UseExternalTabChannelOptions = {
    onMessage?: (broadcastMessage: MessageEvent) => void;
};

export function useExternalTabChannel(
    channelId: string,
    options?: UseExternalTabChannelOptions,
) {
    const channel = useMemo(() => {
        if (typeof BroadcastChannel === 'undefined') {
            return undefined;
        }

        const channel = new BroadcastChannel(channelId);
        channel.onmessage = options?.onMessage || null;
        return channel;
    }, [channelId, options?.onMessage]);

    useEffect(() => {
        return () => channel?.close();
    }, [channel]);

    return channel;
}
