import loadable from '@loadable/component';
import React, { FunctionComponent } from 'react';
import { PaginationData } from '../../../../types';
import './SearchPivotsContainer.scss';
import { SearchPivotSuggestionsProps } from './SearchPivotSuggestionsContainer';

const SearchPivotSuggestions: FunctionComponent<SearchPivotSuggestionsProps> =
    loadable(() => import('./SearchPivotSuggestionsContainer'));

/**
 * Suggested actions for the user to take given low, empty, or
 * end of search results.
 *
 * Always displays the trending searches footer.
 *
 * @param {*} props
 */
type SearchResultsPivotsProps = {
    isLoading: boolean;
    pagination: PaginationData;
};

export const SearchPivotsContainer = ({
    isLoading,
    pagination,
}: SearchResultsPivotsProps) => {
    const isNextDisabled = pagination.page === pagination.pageCount;
    const hasEventResults = pagination.objectCount > 0;
    const shouldRenderSuggestions =
        !isLoading && (!hasEventResults || isNextDisabled);

    return (
        <div className="search-result-pivots">
            {shouldRenderSuggestions && (
                <SearchPivotSuggestions
                    isLoading={isLoading}
                    hasEventResults={hasEventResults}
                    isNextDisabled={isNextDisabled}
                />
            )}
        </div>
    );
};
