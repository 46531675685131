import * as React from 'react';

const TrendingSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="trending_svg__eds-icon--trending_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="trending_svg__eds-icon--trending_base"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M16.5 6v1h3.8l-7.9 7.8-3.2-3.2L2 18.2l.7.8 6.5-6 3.2 3.3L21 7.7v3.8h1V6z"
        />
    </svg>
);

TrendingSvg.displayName = 'TrendingSvg';
export default TrendingSvg;
