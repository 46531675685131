import { FormattedEvent } from '@eventbrite/event-renderer';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import { differenceBy, slice } from 'lodash';

interface QuestionAndAnswerProps {
    isOnline: boolean;
    price?: GenericLazyString | string;
    format?: GenericLazyString | string;
    date?: GenericLazyString | string;
    city?: GenericLazyString | string;
}

export const getSortedOrganicEvents = (organicEvents: FormattedEvent[]) => {
    const filteredOrganicEvents = organicEvents.filter((obj) => {
        return obj.startDate && obj.startTime;
    });
    const sortedOrganicEvents = filteredOrganicEvents.sort((a, b) => {
        /* eslint-disable  @typescript-eslint/no-non-null-assertion */
        // We are sure that startDate will have value as it is filtered in the previous step
        const dt_a = new Date(a.startDate!.concat(' ', a.startTime));
        const dt_b = new Date(b.startDate!.concat(' ', b.startTime));
        if (dt_a < dt_b) {
            return -1;
        } else if (dt_a > dt_b) {
            return 1;
        } else {
            return 0;
        }
    });
    return sortedOrganicEvents;
};

export const getBestEvents = (
    organicEvents: FormattedEvent[],
    nearApproachingEvents: FormattedEvent[],
) => {
    /*
        Best events are populated based on the below simple logic

        nearApproachingEvents list are the first four events of organic events which are sorted based on the start date and time.
        We exclude the nearApproachingEvents list from the original organic events list and get the other four events as best events.
        If the difference does not have sufficient(four) events in that case we get the short of events
        from nearApproachingEvents list.
    */
    const remainingEvents = differenceBy(
        organicEvents,
        nearApproachingEvents,
        'id',
    );
    let bestEvents: FormattedEvent[];
    if (remainingEvents.length >= 4) {
        bestEvents = slice(remainingEvents, 0, 4);
    } else {
        bestEvents = [
            ...remainingEvents,
            ...nearApproachingEvents.slice(0, 4 - remainingEvents.length),
        ];
    }
    return bestEvents;
};

export const getBestEventsFaq = (props: QuestionAndAnswerProps) => {
    const { isOnline, format, date, price } = props;
    let questionText, answerText;

    // Examples for NON ONLINE
    // Translators: "What are the best events to attend?"
    // Translators: "What are the best events to attend today?"
    // Translators: "What are the best class events to attend?"
    // Translators: "What are the best free class events to attend?"
    // Translators: "What are the best free events to attend?"
    // Translators: "What are the best free class events to attend today?"
    // Translators: "What are the best class events to attend today?"
    // Translators: "What are the best free events to attend today?"

    questionText = gettext(
        'What are the best %(price)s %(format)s events to attend %(date)s?',
        {
            price,
            format,
            date,
        },
    );

    // Translators: "According to Eventbrite, the best events are:"
    // Translators: "According to Eventbrite, the best events today are:"
    // Translators: "According to Eventbrite, the best class events are:"
    // Translators: "According to Eventbrite, the best free class events are:"
    // Translators: "According to Eventbrite, the best free events are:"
    // Translators: "According to Eventbrite, the best free class events today are:"
    // Translators: "According to Eventbrite, the best class events today are:"
    // Translators: "According to Eventbrite, the best free events today are:"
    answerText = gettext(
        'According to Eventbrite, the best %(price)s %(format)s events %(date)s are:',
        {
            price,
            format,
            date,
        },
    );
    if (isOnline) {
        questionText = gettext(
            'What are the best %(price)s online %(format)s events to attend %(date)s?',
            {
                price,
                format,
                date,
            },
        );
        answerText = gettext(
            'According to Eventbrite, the best %(price)s online %(format)s events %(date)s are: ',
            {
                price,
                format,
                date,
            },
        );
    }
    return { questionText, answerText };
};

export const getTrendingEventsFaq = (props: QuestionAndAnswerProps) => {
    // Translators: "Which events are currently trending online?"
    // Translators: "Which events are currently trending in New York?"
    const questionText = props.isOnline
        ? gettext('Which events are currently trending online?')
        : gettext('Which events are currently trending in %(city)s?', {
              city: props.city,
          });
    // Translators: "The trending searches online this month are:"
    // Translators: "The trending searches this month in New York are:"
    const answerText = props.isOnline
        ? gettext('The trending searches online this month are:')
        : gettext('The trending searches this month in %(city)s are:', {
              city: props.city,
          });
    return { questionText, answerText };
};

export const getUpComingEventsFaq = (props: QuestionAndAnswerProps) => {
    const { isOnline, format, price } = props;
    let questionText, answerText;

    // Examples for NON ONLINE
    // Translators: "Which events are coming up?"
    // Translators: "Which class events are coming up?"
    // Translators: "Which free class events are coming up?"
    // Translators: "Which free events are coming up?"

    questionText = gettext('Which %(price)s %(format)s events are coming up?', {
        price,
        format,
    });

    // Translators: "Discover the best events happening soon – you don’t want to miss them:"
    // Translators: "Discover the best class events happening soon – you don’t want to miss them:"
    // Translators: "Discover the best free class events happening soon – you don’t want to miss them:"
    // Translators: "Discover the best free events happening soon – you don’t want to miss them:"
    answerText = gettext(
        'Discover the best %(price)s %(format)s events happening soon – you don’t want to miss them:',
        {
            price,
            format,
        },
    );
    if (isOnline) {
        questionText = gettext(
            'Which %(price)s online %(format)s events are coming up?',
            {
                price,
                format,
            },
        );
        answerText = gettext(
            'Discover the best %(price)s online %(format)s events happening soon – you don’t want to miss them:',
            {
                price,
                format,
            },
        );
    }
    return { questionText, answerText };
};
