import { AlertChunky } from '@eventbrite/eds-iconography';
import { TYPE_ERROR } from '@eventbrite/eds-notification';
import { WithMainControlsProps } from '@eventbrite/eds-structure';
import { gettext } from '@eventbrite/i18n';
import { getCurrentNormalizedUrl } from '@eventbrite/search-utils';
import { cleanAction, getAction } from '@eventbrite/user-action-cookie';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useMount } from 'react-use';
import {
    restoreEventSearchFromUrl,
    syncEventSearchAndUrl,
} from '../../redux/actions/previousUrlContext';
import { showErrorNotification as triggerErrorNotification } from '../../redux/actions/ui';
import { DiscoverState, WithDispatch } from '../../types';
import { SearchResponseEvents } from '../../utils/transformation';
import { SearchDataParams } from './SearchPageData';

type UseEventSearchSyncEffectsParams = Pick<SearchDataParams, 'eventSearch'> &
    Pick<RouteComponentProps, 'history'> &
    WithDispatch &
    Pick<DiscoverState, 'thingsToDoShelf'> &
    Pick<DiscoverState, 'trendingSearchesResult'>;

/*
 * This function ensures the redux event search and the url are sync
 * We are unable right now to generate a event search object from the url, so we need to sync both sources of truth.
 * Ideally, event search should come from the url and act as a single source of truth
 */
export function useEventSearchSyncEffects({
    dispatch,
    history,
    eventSearch,
    thingsToDoShelf,
    trendingSearchesResult,
}: UseEventSearchSyncEffectsParams) {
    const currentUrl = getCurrentNormalizedUrl(history);

    useEffect(() => {
        // Check if eventSearch is synced after url change
        // This is relevant when user navigates back and forward
        dispatch(restoreEventSearchFromUrl(history));
    }, [dispatch, history, currentUrl]);

    useEffect(() => {
        // Check if the url is synced with the new eventSearch data
        dispatch(syncEventSearchAndUrl(history));
    }, [
        dispatch,
        history,
        eventSearch,
        thingsToDoShelf,
        trendingSearchesResult,
    ]);
}

type RouteEffectsParams = Pick<RouteComponentProps, 'location'> &
    SearchResponseEvents & {
        isAuthenticated: boolean | undefined;
        onTrackPageView: Function;
    };

export function useSearchBaseTrackingEffects({
    isAuthenticated,
    onTrackPageView,
    pagination,
    events,
    location,
}: RouteEffectsParams) {
    const currentUrl = `${location.pathname}${location.search}`;

    // TODO: We should update this effect only with route changes
    // In order to avoid the exhaustive deps problems we need to pass the track information from elsewhere
    // If not, this effect triggers with pointer reference updates of pagination and events.organic
    // Maybe fetching directly from the store
    useEffect(() => {
        onTrackPageView(
            events.organic,
            pagination,
            Boolean(events.topMatches.length),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUrl]);

    useMount(() => {
        const action = getAction();

        if (
            !isAuthenticated ||
            !action ||
            action.action === 'FollowedOrganizerFilterAttempt'
        ) {
            return;
        }

        cleanAction();
    });
}

type UseErrorHandlingEffectsParams = Pick<
    WithMainControlsProps,
    'addMainNotification' | 'hideMainNotification'
> &
    WithDispatch & {
        error?: unknown;
        showErrorNotification?: boolean;
    };

export function useErrorHandlingEffects({
    showErrorNotification,
    addMainNotification,
    hideMainNotification,
    dispatch,
    error,
}: UseErrorHandlingEffectsParams) {
    useEffect(() => {
        if (showErrorNotification) {
            addMainNotification({
                type: TYPE_ERROR,
                children: showErrorNotification,
                iconType: <AlertChunky />,
                hasCloseButton: true,
            });
        } else {
            hideMainNotification();
        }
    }, [showErrorNotification, addMainNotification, hideMainNotification]);

    useEffect(() => {
        if (!error) {
            return;
        }

        dispatch(
            triggerErrorNotification(
                gettext('Error finding events. Please try again'),
            ),
        );
    }, [dispatch, error]);
}
