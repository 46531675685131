import {
    SearchEventCardClickBehaviourExperiment,
    SearchEventCardSignalsVisibilityExperiment,
} from '@eventbrite/discover-utils';
import {
    areUrgencySignalsActive,
    FormattedEvent,
    isPromoCodeActive,
} from '@eventbrite/event-renderer';
import loadable from '@loadable/component';
import React from 'react';
import { SearchEventCardStyleSwitcher } from '../../../../containers/SearchEventCardStyleSwitcher';
import { SearchEventCardStylesSwitcherProps } from '../../../../containers/SearchEventCardStyleSwitcher/SearchEventCardStyleSwitcher';
import { useServerEnrolledStatsigExperiment } from '../../../../context/experiments';

const ExpandDedupedEventCTA = loadable(() => import('./ExpandDedupedEventCTA'));
const SearchEventCardDebugInfo = loadable(
    () => import('./SearchEventCardDebugInfo'),
);

export type SearchResultEventCardProps = SearchEventCardStylesSwitcherProps & {
    shouldShowDebugOutput?: boolean;
    position: number;
    isExpansionsLoading: boolean;
    page?: number;

    //Internal Tracking Callbacks that will need an audit
    trackClickInternal?: (
        position: number,
        eventId: string,
        ticketsBy: string,
    ) => void;
};

/**
 * Temporary formatting layer to translate the incoming event props
 * to the FormattedEvent shape that the new event card is expecting.
 *
 * The plan is to use the transformation util exported by EventRenderer
 * to format events and standardize this, but in flight PR updating
 * state management should land first. In the interim doing this formatting
 * layer to align the new card with current structure.
 *
 *
 *
 * @param props
 * @returns
 */
export const SearchResultEventCard: React.FunctionComponent<
    SearchResultEventCardProps
> = ({
    event,
    position,
    shouldShowDebugOutput,
    trackClickInternal,
    ...props
}) => {
    const { id, ticketsBy, organizerFollowerCount = 0, name } = event;
    const shouldShowExpandDedupedEventCTA =
        event.dedup &&
        typeof event.dedup.count === 'number' &&
        event.dedup.count > 1;

    const renderDebugInfo =
        event.debugInfo?.esDebugInfo && shouldShowDebugOutput;

    const onClick = React.useCallback(() => {
        //position 1 indexed, but tracking expects 0 indexed values
        const rank = position - 1;
        trackClickInternal?.(rank, id, ticketsBy || 'Eventbrite');
    }, [trackClickInternal, id, ticketsBy, position]);

    const statsigMetadata = {
        searchRank: `${position}`,
        searchPage: `${props.page}`,
    };

    const searchEventCardVariant = useServerEnrolledStatsigExperiment({
        name: SearchEventCardClickBehaviourExperiment.name,
        paramName: SearchEventCardClickBehaviourExperiment.paramName,
        defaultValue: SearchEventCardClickBehaviourExperiment.values.control,
        enabled: !!event.isPromoted,
    });

    const hasReducedSignals = useReducedSignalsExperiment(event);

    const hasFullClick =
        searchEventCardVariant.variant ===
        SearchEventCardClickBehaviourExperiment.values.fullClick;
    const hasCTA =
        searchEventCardVariant.variant ===
        SearchEventCardClickBehaviourExperiment.values.cta;

    return (
        <>
            <SearchEventCardStyleSwitcher
                event={event}
                onClick={onClick}
                statsigMetadata={statsigMetadata}
                hasFullClick={hasFullClick}
                hasCTA={hasCTA}
                hasReducedSignals={hasReducedSignals}
                {...props}
            />

            {shouldShowExpandDedupedEventCTA ? (
                <ExpandDedupedEventCTA
                    dedup={event.dedup}
                    name={name}
                    eventId={id}
                />
            ) : null}
            {renderDebugInfo ? (
                <SearchEventCardDebugInfo
                    debugInfo={event.debugInfo}
                    position={position}
                    numFollowers={organizerFollowerCount}
                />
            ) : null}
        </>
    );
};

function useReducedSignalsExperiment(event: FormattedEvent) {
    const hasUrgencySignals = areUrgencySignalsActive(event);
    const hasDiscountCode = isPromoCodeActive(event.openDiscount);
    const hasBOGOLabel = !!event.specialDiscounts?.hasBogoTickets;

    const hasMoreThanOneSignal =
        (hasUrgencySignals && hasDiscountCode) ||
        (hasUrgencySignals && hasBOGOLabel) ||
        (hasDiscountCode && hasBOGOLabel);

    const signalsVisibility = useServerEnrolledStatsigExperiment({
        name: SearchEventCardSignalsVisibilityExperiment.name,
        paramName: SearchEventCardSignalsVisibilityExperiment.paramName,
        defaultValue: SearchEventCardSignalsVisibilityExperiment.values.control,
        enabled: hasMoreThanOneSignal,
    });

    return (
        signalsVisibility.variant ===
        SearchEventCardSignalsVisibilityExperiment.values.reduced
    );
}

export default SearchResultEventCard;
