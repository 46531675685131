import { track } from '@eventbrite/datalayer-library';
import { MegaphoneThin } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';

import { Button, Typography } from '@eventbrite/marmalade';
import { logEvent } from '@eventbrite/statsig';
import React from 'react';
import { STATSIG_SEARCH_VIDEO_ADS_UPSELL } from '../../../../components/SearchVideoAdsUpsell';
import styles from './SearchResultPanelAdsUpsell.module.scss';

const HEAP_CLICK_ADS_LINK = 'Ads Entry point - Click - Search link';
const CONSUMER_SEARCH_ENTRY_POINT_CLICK =
    'EB Ads - Consumer Search - Entry Point - Click';

export type SearchResultsPanelAdsUpsellProps = React.PropsWithoutRef<{}>;

export function SearchResultsPanelAdsUpsell(
    props: SearchResultsPanelAdsUpsellProps,
) {
    const trackUpsellLink = () => {
        logEvent(STATSIG_SEARCH_VIDEO_ADS_UPSELL);
        track({
            eventName: HEAP_CLICK_ADS_LINK,
            eventData: {
                interface: 'Search - Promoted',
            },
        });
    };

    return (
        <aside className={styles.root} {...props}>
            <section className={styles.callout}>
                <MegaphoneThin className={styles.icon} />
                <div>
                    <Typography
                        className={styles.calloutText}
                        variant="body-md-bold"
                        color="black"
                        as="span"
                    >
                        {gettext('Your event would look great here!')}
                    </Typography>
                    <Typography
                        className={styles.calloutText}
                        variant="body-sm"
                        color="black"
                    >
                        {gettext(
                            'Promote your event with Eventbrite Ads, and we’ll put it in front of tons of people searching for experiences like yours.',
                        )}
                    </Typography>
                </div>
            </section>
            <a
                href="/organizations/marketing/eventbrite-ads"
                target="_blank"
                onClick={trackUpsellLink}
                className={styles.link}
            >
                <Button
                    variant="ghost"
                    data-heap-id={CONSUMER_SEARCH_ENTRY_POINT_CLICK}
                >
                    {gettext('Get started')}
                </Button>
            </a>
        </aside>
    );
}
