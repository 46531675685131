import loadable from '@loadable/component';
import React from 'react';
import { DiscoverEventCardProps } from './types';

type EventCardMobileImportMap = Record<
    'control',
    React.FunctionComponent<DiscoverEventCardProps>
>;

type EventCardDesktopImportMap = Record<
    'control',
    React.FunctionComponent<DiscoverEventCardProps>
>;

export const EXPERIMENT_SEARCH_CARD_MAP_MOBILE: EventCardMobileImportMap = {
    control: loadable(() => import('./DiscoverVerticalEventCard')),
};

export const EXPERIMENT_SEARCH_CARD_MAP_DESKTOP: EventCardDesktopImportMap = {
    control: loadable(() => import('./DiscoverHorizontalEventCard')),
};

export type { DiscoverEventCardProps };
