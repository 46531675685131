import * as constants from './constants';

const isFirstPage = (pageNumber: number) => pageNumber === 1;

const isLastPage = (pageNumber: number, pageCount: number) =>
    pageCount <= 1 || pageNumber >= pageCount;

/**
 * returns an object for page with
 * page number, url and if that page is selected
 * @example
 * // returns [{ number: 2, url: '', isSelected: true}]
 * scenario:
 * Viewport Large
 * page_number: 2, page_count: 3
 * @returns {Array} range of pages to display
 */
const getPages = (pageNumber: number) =>
    [pageNumber].map((number) => ({
        number,
        isSelected: pageNumber === number,
    }));

interface GetPaginationInfoParams {
    pageNumber: number;
    totalNumResults: number;
    currentResultsPerPage?: number;
}
export const getPaginationInfo = ({
    pageNumber = 1,
    totalNumResults = 0,
    currentResultsPerPage = constants.DEFAULT_RESULTS_PER_PAGE,
}: GetPaginationInfoParams) => {
    const calculatedPageCount = Math.ceil(
        totalNumResults / currentResultsPerPage,
    );

    const pages = getPages(pageNumber);

    return {
        isFirstPage: isFirstPage(pageNumber),
        isLastPage: isLastPage(pageNumber, calculatedPageCount),
        pages,
        pageCount: calculatedPageCount,
    };
};
