import {
    Accordion,
    AccordionContent,
    AccordionHeader,
} from '@eventbrite/eds-accordion';
import { GenericLazyString } from '@eventbrite/i18n';
import { Divider } from '@eventbrite/marmalade';
import React from 'react';
import { cleanExtraSpaces } from '../../../../utils/general';
import style from './FAQSchema.module.scss';

type FAQItemProps = {
    questionText: string | GenericLazyString;
    answerText: string | GenericLazyString;
    data: {
        name: string | GenericLazyString;
        url: string;
        id: string;
    }[];
};

export const FAQItem = (props: FAQItemProps) => {
    const { data, questionText, answerText } = props;

    return (
        <>
            <Accordion>
                <div
                    itemScope
                    itemProp="mainEntity"
                    itemType="https://schema.org/Question"
                >
                    <AccordionHeader>
                        <div className={style.accordionHeader} itemProp="name">
                            {/* .replace(' ?', '?') is used to remove an additional space added when there is no date value to question template */}
                            {cleanExtraSpaces(questionText).replace(' ?', '?')}
                        </div>
                    </AccordionHeader>
                    <AccordionContent>
                        <div className={style.accordionContent}>
                            <div
                                itemScope
                                itemProp="acceptedAnswer"
                                itemType="https://schema.org/Answer"
                            >
                                <div
                                    className={style.accordionAnswer}
                                    itemProp="text"
                                >
                                    {cleanExtraSpaces(answerText)}
                                    <div className={style.accordionUL}>
                                        {data &&
                                            data.map(({ name, url, id }) => (
                                                <li key={'listItem' + id}>
                                                    <a
                                                        key={'linkText' + id}
                                                        href={url}
                                                    >
                                                        {name}
                                                    </a>{' '}
                                                </li>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AccordionContent>
                </div>
            </Accordion>
            <Divider />
        </>
    );
};
