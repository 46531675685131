import { track } from '@eventbrite/datalayer-library';
import { PlayFill } from '@eventbrite/eds-iconography';
import { Icon } from '@eventbrite/marmalade';
import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';
import videoThumbnail from './assets/video-thumbnail.webp';
import styles from './VideoAdsUpsell.module.scss';

export type VideoAdsUpsellProps = React.PropsWithoutRef<{
    className?: string;
    trackingMetadata: VideoAdsUpsellTrackingMetadata;
}>;

type VideoAdsUpsellTrackingMetadata = {
    component: string;
    interface: string;
};

const adsVideoSource =
    'https://cdn.evbstatic.com/s3-build/videos/eb-ads_540.webm';

export function VideoAdsUpsell({
    className,
    trackingMetadata,
    ...props
}: VideoAdsUpsellProps) {
    const {
        showNativeControls,
        videoRef,
        handleHover,
        handleLeave,
        handleClick,
    } = useVideoControls();
    const { onVideoClick, onVideoEnd, onVideoPlay } =
        useTrackingHandlers(trackingMetadata);

    // It seems the "muted" attribute is problematic for React and throws some weird errors in testing
    // In order to sort this out, this is one of the recommended solutions
    // https://github.com/facebook/react/issues/10389
    // https://github.com/testing-library/react-testing-library/issues/470
    useEffect(() => {
        if (!videoRef.current) {
            return;
        }

        videoRef.current.muted = !showNativeControls;
    }, [showNativeControls, videoRef]);

    return (
        <section
            className={classnames(className, styles.videoContainer)}
            {...props}
        >
            {!showNativeControls && (
                <div
                    className={styles.overlay}
                    onMouseEnter={handleHover}
                    onMouseLeave={handleLeave}
                    onClick={() => {
                        handleClick();
                        onVideoClick();
                    }}
                    data-testid="video-ads-upsell-overlay"
                    role="button"
                >
                    <button
                        className={styles.playButton}
                        aria-label="Play video ads"
                    >
                        <Icon>
                            <PlayFill />
                        </Icon>
                    </button>
                </div>
            )}
            <video
                className={styles.video}
                controls={showNativeControls}
                ref={videoRef}
                poster={videoThumbnail}
                onEnded={onVideoEnd}
                onPlay={onVideoPlay}
                data-testid="video-ads-element"
            >
                <source src={adsVideoSource} type="video/webm" />
                Your browser does not support HTML video.
            </video>
        </section>
    );
}

function useVideoControls() {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [showNativeControls, setControlsVisibility] = React.useState(false);

    const handleHover = () => {
        if (!videoRef.current) {
            return;
        }

        videoRef.current.play();
    };

    const handleLeave = () => {
        if (!videoRef.current) {
            return;
        }

        videoRef.current.pause();
        videoRef.current.currentTime = 0;
    };

    const handleClick = () => {
        setControlsVisibility(true);
        setTimeout(() => videoRef.current?.play(), 0);
    };

    return {
        showNativeControls,
        videoRef,
        handleHover,
        handleLeave,
        handleClick,
    };
}

function useTrackingHandlers(trackingMetadata: VideoAdsUpsellTrackingMetadata) {
    const [hasVideoPlayed, setVideoPlayed] = React.useState(false);

    const getHeapEventName = (event: string) =>
        `${trackingMetadata.component} - ${event}`;

    const onVideoEnd = () =>
        track({
            eventName: getHeapEventName('Auto - Video ends'),
            eventData: { interface: trackingMetadata.interface },
        });

    const onVideoPlay = () => {
        if (hasVideoPlayed) {
            return;
        }

        setVideoPlayed(true);
        track({
            eventName: getHeapEventName('Hover - Video plays'),
            eventData: { interface: trackingMetadata.interface },
        });
    };

    const onVideoClick = () =>
        track({
            eventName: getHeapEventName('Click - Show video controls / unmute'),
            eventData: { interface: trackingMetadata.interface },
        });

    return {
        onVideoEnd,
        onVideoPlay,
        onVideoClick,
    };
}
