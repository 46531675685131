import { EventSearch } from '@eventbrite/discover-utils';
import {
    DestinationEvent,
    EventUrgencySignalsApiData,
} from '@eventbrite/event-renderer';
import { QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';
import { merge } from 'lodash/fp';
import isEmpty from 'lodash/isEmpty';
import mapKeys from 'lodash/mapKeys';
import { useMemo } from 'react';
import { fetchSearchEventExpansions } from '../../redux/api/expansions';
import { SearchApiResponse } from '../../redux/api/search';
import { getEventSearchQueryKey } from './SearchPageData';

const expansionsQueryKey: QueryKey = ['pageExpansion'];
export function useInitialEventsExpansionQueryState() {
    const queryClient = useQueryClient();
    const state = queryClient.getQueryState(expansionsQueryKey);
    return state?.status;
}

export function useInitialEventsExpansion(
    eventIds: string[],
    eventSearch: EventSearch,
    useBff = false,
) {
    const queryClient = useQueryClient();
    const queryKey = useMemo(
        () => getEventSearchQueryKey(eventSearch),
        [eventSearch],
    );

    // Fetch expansions for server events
    // The intent here is to manually apply expansions on the events in the store.
    // This only applies to the initial server render.
    // When we call the search endpoint from the user searching
    // in the app, we already get the expansions.
    // Skipping the expansions on the server is a performance optimization.
    // TODO: Unify with event-renderer in the future
    // useMount(async () => {
    // const expansions = await getEventExpansions(initialData);

    const { data: expansions, ...remainingQueryState } = useQuery(
        expansionsQueryKey,
        () => getEventExpansions(eventIds, useBff),
        {
            onSuccess: (expansions) => {
                queryClient.setQueryData<SearchApiResponse>(
                    queryKey,
                    updateEventsWithExpansions(expansions),
                );
            },
        },
    );

    return { expansions, ...remainingQueryState };
}

export async function getEventExpansions(
    eventIds: string[],
    useBff = false,
): Promise<DestinationEvent[]> {
    if (!eventIds.length) {
        return [];
    }

    const { events: eventExpansions } = await fetchSearchEventExpansions(
        eventIds,
        useBff,
    );
    return eventExpansions;
}

function updateEventsWithExpansions(expansions: DestinationEvent[]) {
    // Transform into map for faster lookup in the updater function
    const expansionsMap: Record<string, DestinationEvent> = mapKeys(
        expansions,
        'id',
    );

    return (eventsCache: SearchApiResponse | undefined) => {
        if (!eventsCache) {
            return;
        }
        const { data } = eventsCache;

        const eventUpdater = (event: DestinationEvent) => {
            const expandedData = expansionsMap[event.id];
            if (!expandedData) {
                return event;
            }
            return addExpansionsToEvent(event, expandedData);
        };

        const {
            events: { results = [], promoted_results = [], top_match = [] },
        } = data;
        return {
            ...eventsCache,
            data: {
                ...data,
                events: {
                    ...data.events,
                    results: results.map(eventUpdater),
                    promoted_results: promoted_results.map(eventUpdater),
                    top_match: top_match.map(eventUpdater),
                },
            },
        };
    };
}

function addExpansionsToEvent(
    event: DestinationEvent,
    {
        series = event.series,
        event_sales_status = event.event_sales_status,
        primary_organizer = event.primary_organizer,
        ticket_availability,
        public_collections = event.public_collections,
        urgency_signals,
        saves = event.saves,
    }: DestinationEvent,
) {
    return {
        ...event,
        series,
        event_sales_status,
        primary_organizer,
        // Open discount is nested here, so we need to merge data
        ticket_availability: merge(
            ticket_availability,
            event.ticket_availability,
        ),
        public_collections,
        // TODO: Remove when EB-228408 is done
        urgency_signals: hasUrgencySignalsData(urgency_signals)
            ? urgency_signals
            : event.urgency_signals,
        saves,
    };
}

// TODO: Remove when EB-228408 is done
function hasUrgencySignalsData(
    urgencySignals: EventUrgencySignalsApiData | null | undefined,
) {
    if (!urgencySignals) {
        return false;
    }

    return (
        !isEmpty(urgencySignals.categories) || !isEmpty(urgencySignals.messages)
    );
}
