import { HAS_WINDOW } from '@eventbrite/feature-detection';
import React from 'react';
import { Pagination } from '../Pagination';
import styles from './SearchFooter.module.scss';

export interface SearchFooterProps {
    currentPage: number;
    totalNumResults: number;
    hasEventResults?: boolean;
    handleGetPage: Function;
}

export class SearchFooter extends React.Component<SearchFooterProps> {
    _onGetPage = (pageNumber: number) => {
        const { handleGetPage } = this.props;

        if (HAS_WINDOW) {
            window.scrollTo(0, 0);
        }
        // Offset by -1 because our Redux pagination assumes pages start at 0, EDS assumes pages start at 1
        handleGetPage(pageNumber);
    };

    render() {
        const { currentPage, totalNumResults, hasEventResults } = this.props;

        if (!hasEventResults) {
            return null;
        }

        return (
            <footer data-testid="search-footer" className={styles.searchFooter}>
                <Pagination
                    pageNumber={currentPage}
                    totalNumResults={totalNumResults}
                    // eslint-disable-next-line react/jsx-handler-names
                    onSelected={this._onGetPage}
                />
            </footer>
        );
    }
}
