import { Dialog } from '@eventbrite/eds-dialog';
import { Modal } from '@eventbrite/eds-modal';
import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import OverlayContext from '../../context/Overlay';

interface ConfrimationModalBodyProps {
    contentHeader: React.ReactNode;
    contentFooter: React.ReactNode;
}

export const ConfirmationModalBody: React.FunctionComponent<
    ConfrimationModalBodyProps
> = (props) => (
    <section className="eds-g-cell eds-g-cell-1-1 eds-g-cell--has-overflow">
        <div className="eds-align--center-horizontal eds-l-mar-top-8">
            {props.contentHeader}
        </div>
        <div className="eds-align--center-horizontal eds-l-mar-top-8">
            {props.children}
        </div>
        <div className="eds-align--center-horizontal eds-l-mar-top-8">
            {props.contentFooter}
        </div>
    </section>
);

const ConfirmationOverlay = () => {
    const { getOverlayConfig }: any = useContext(OverlayContext);
    const config = getOverlayConfig();

    if (!config) {
        return null;
    }

    const { overlayType = 'modal', children, ...options } = config;

    const Overlay = overlayType === 'modal' ? Modal : Dialog;

    const element: Element | HTMLElement | null =
        document.getElementById('root');

    return ReactDOM.createPortal(
        <Overlay {...options}>{children}</Overlay>,
        element as Element,
    );
};

export default ConfirmationOverlay;
