import { gettext } from '@eventbrite/i18n';
import loadable from '@loadable/component';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { DiscoverState } from '../../../../types';
import { ThingsToDoShelfState } from '../../../../types/ThingsToDoShelf';

import './ThingsToDoShelfContainer.scss';

const ThingsToDoShelf = loadable(
    () => import('../../../../components/ThingsToDoShelf'),
    {
        resolveComponent: (component: { ThingsToDoShelf: React.ReactNode }) =>
            component.ThingsToDoShelf,
    },
);

type ThingsToDoShelfSectionProps = ThingsToDoShelfState;

const ThingsToDoShelfSection = ({
    tags,
    header,
}: ThingsToDoShelfSectionProps) => {
    const shelfHeader = {
        title: gettext('Things to do around %(geo)s', {
            geo: header.placeName,
        }),
        url: header.url,
    };

    const shelfTags = tags.map((tag) => ({
        displayText: gettext('Things to do in %(geo)s', {
            geo: tag.placeName,
        }),
        linkUrl: tag.linkUrl,
    }));

    const showThingsToDoShelf = shelfTags.length > 0;

    return (
        <>
            {showThingsToDoShelf && (
                <ThingsToDoShelf
                    tags={shelfTags}
                    header={shelfHeader}
                    shelfClassName="dsrp-ttd-shelf"
                    tagsConfig={{
                        backgroundColor: 'white',
                        tagColor: '#F8F7FA',
                    }}
                    shelfBackgroundColor="white"
                />
            )}
        </>
    );
};

export const _mapStateToProps = (state: DiscoverState) => {
    return {
        tags: state.thingsToDoShelf.tags,
        header: state.thingsToDoShelf.header,
    };
};

export const ThingsToDoShelfContainer: FunctionComponent = connect(
    _mapStateToProps,
)(ThingsToDoShelfSection);
