import { CloseIcon, IconButton } from '@eventbrite/marmalade';
import classnames from 'classnames';
import React from 'react';
import styles from './CloseableDialog.module.scss';

export type CloseableDialogProps = React.HTMLAttributes<unknown> & {
    as?: React.ElementType;
    iconClassName?: string;
};

export function CloseableDialog({
    className,
    as: Component = 'div',
    iconClassName,
    children,
    ...props
}: CloseableDialogProps) {
    const [isClosed, setIsClosed] = React.useState(false);

    if (isClosed) {
        return null;
    }

    return (
        <Component className={classnames(styles.root, className)} {...props}>
            <IconButton
                icon={<CloseIcon />}
                aria-label="Close dialog"
                className={classnames(iconClassName, styles.icon)}
                onClick={() => setIsClosed(true)}
            />
            {children}
        </Component>
    );
}
