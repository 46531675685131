import { FormattedEvent } from '@eventbrite/event-renderer';
import { useEnvContext } from '../../../../context';

export function useSearchUpsell(events: FormattedEvent[]) {
    const { user } = useEnvContext();
    const hasPromotedEvents = events.find((event) => event.isPromoted);

    return hasPromotedEvents && user?.orgHasEvents;
}

export const STATSIG_SEARCH_VIDEO_ADS_UPSELL =
    'Search - Promoted - Click - Ads Upsell link';
