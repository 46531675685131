import React from 'react';
import './Carousel.scss';
import { CarouselProps } from './types';

export const Carousel = <T,>(props: CarouselProps<T>): JSX.Element => {
    const { cards, children, justifyContentValue = 'center' } = props;
    return (
        <div
            className={`carousel ${
                props.noScrollMobile ? 'carousel__no-scroll' : ''
            }`}
        >
            <div
                className="carousel-internal"
                style={{
                    justifyContent: props.noScrollMobile
                        ? justifyContentValue
                        : undefined,
                }}
            >
                {cards.map((cards, index) => children(cards, index))}
            </div>
        </div>
    );
};
