import {
    EXPERIENCES_FILTER,
    STATSIG_EXPERIENCE_FILTER_ClICK,
} from '@eventbrite/search-utils';
import { logEvent } from '@eventbrite/statsig';
import { $FixMe } from '@eventbrite/ts-utils';
import React from 'react';
import { connect } from 'react-redux';
import { Carousel, CarouselCard } from '../../../../../../components/Carousel';
import { handleApplyFilter as handleApplyFilterAction } from '../../../../../../redux/actions/search';
import { DiscoverState } from '../../../../../../types';
import { CATEGORY_CONTENT_MAP } from '../../../../../categoryBrowse/constants';
import { experiences } from './assets/experiences';

import './ExperienceFilters.scss';
interface ExperienceFiltersProps {
    activeExperiences: string[];
    handleApplyFilterAction: Function;
}

export const ExperienceFilters: React.FunctionComponent<
    ExperienceFiltersProps
> = ({ activeExperiences = [], handleApplyFilterAction }) => {
    const handleToggleExperienceFilter = (experience = '') => {
        if (!experience) {
            return;
        }

        const filtersToApply = activeExperiences.includes(experience)
            ? activeExperiences.filter((filter) => filter !== experience)
            : [...activeExperiences, experience];

        logEvent(STATSIG_EXPERIENCE_FILTER_ClICK, undefined, {
            experience: experience,
            isApplyingFilter: activeExperiences.includes(experience)
                ? 'true'
                : 'false',
            location: 'carousel',
        });

        handleApplyFilterAction?.({ [EXPERIENCES_FILTER]: filtersToApply }, {});
    };

    return (
        <div
            className="experience-filters-container"
            data-testid="experience-filters"
        >
            <Carousel cards={experiences} justifyContentValue="flex-start">
                {(experience) => {
                    const isActive =
                        experience &&
                        activeExperiences.includes(experience.value);

                    const imageUrl = experience?.hasEBCategory
                        ? CATEGORY_CONTENT_MAP[experience?.imageUrl || '']
                              .imageUrlSmall
                        : experience?.imageUrl;

                    return (
                        <button
                            onClick={() =>
                                handleToggleExperienceFilter(experience?.value)
                            }
                            className={`filter-button ${
                                isActive ? 'active' : ''
                            }`}
                            key={experience?.value}
                            data-testid="experience-filter-button"
                        >
                            <CarouselCard
                                title={experience?.name || ''}
                                imageUrl={imageUrl || ''}
                                imageLoading={'lazy'}
                            />
                        </button>
                    );
                }}
            </Carousel>
        </div>
    );
};

const _mapStateToProps = (state: DiscoverState) => ({
    activeExperiences: state.search.eventSearch.experiences,
});

const _mapDispatchToProps = (dispatch: Function) => ({
    handleApplyFilterAction: (...args: [$FixMe]) =>
        dispatch(handleApplyFilterAction(...args)),
});

export const ExperienceFiltersContainer = connect(
    _mapStateToProps,
    _mapDispatchToProps,
)(ExperienceFilters);
