import {
    EventInteraction,
    usePromotedEvents,
    useTracking,
} from '@eventbrite/ads';
import { PromotedActionsMenuEventCard } from '@eventbrite/ads-event-card-extra';
import { EventSearch } from '@eventbrite/discover-utils';
import { gettext } from '@eventbrite/i18n';
import { Typography } from '@eventbrite/marmalade';
import { computeLocationInfo, getGeoPoint } from '@eventbrite/search-utils';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React from 'react';
import DiscoverVerticalEventCard from '../../../../components/DiscoverEventCard/DiscoverVerticalEventCard';
import { SpinnerWheel } from '../../../../components/Spinner/Spinner';
import { useEnvContext } from '../../../../context';
import {
    aggregateTrackingContext,
    SEARCH_PLACEMENT_NAME,
    SPONSORED_ADS_SUBINTERFACE,
} from '../../utils/tracking';
import styles from './SearchBasedRecommendedEvents.module.scss';

export type SearchBasedRecommendedEventsProps = {
    className?: string;
    locale: string;
    isAuthenticated: boolean;
    eventSearch: EventSearch;
};

export function SearchBasedRecommendedEvents({
    className,
    locale,
    isAuthenticated,
    eventSearch,
}: SearchBasedRecommendedEventsProps) {
    const { user, request } = useEnvContext();
    const {
        data: promotedEvents = [],
        isLoading,
        removeEvent,
    } = usePromotedEvents({
        slots: 4,
        interfaceName: SEARCH_PLACEMENT_NAME,
        search: getSearchBasedPromotedEvents(eventSearch),
    });

    const getTrackingContext = ({ id, event }: EventInteraction) =>
        aggregateTrackingContext({
            id,
            event,
            events: promotedEvents,
            eventSearch,
            userId: user?.publicId || '',
            locale,
            sessionId: request?.session_id || '',
            guestId: user?.guestId || '',
            subInterface: SPONSORED_ADS_SUBINTERFACE,
        });
    const { ref } = useTracking<HTMLDivElement>(getTrackingContext);

    if (isEmpty(promotedEvents) && !isLoading) {
        return null;
    }

    const events = promotedEvents.map((event) => {
        const statsigLocationString = event.isPromoted
            ? 'Search Based Recommended - Promoted'
            : 'Search Based Recommended - Primary Results';

        return (
            <DiscoverVerticalEventCard
                key={event.id}
                event={event}
                locale={locale}
                isAuthenticated={isAuthenticated}
                isRaised
                statsigLocationString={statsigLocationString}
                moreActions={
                    event.isPromoted && (
                        <PromotedActionsMenuEventCard
                            event={event}
                            onRemoveEvent={removeEvent}
                            getTrackingContext={getTrackingContext}
                        />
                    )
                }
                affCode={'ebdssbdestsearch'}
            />
        );
    });

    return (
        <section
            className={classNames(className, styles.container)}
            data-testid="search-based-recommended-events"
            ref={ref}
        >
            <header>
                <Typography variant="heading-sm">
                    {gettext('Based on your most recent searches')}
                </Typography>
            </header>
            {isLoading ? (
                <section className={styles.spinnerContainer}>
                    <SpinnerWheel />
                </section>
            ) : (
                <section className={styles.eventContainer}>{events}</section>
            )}
        </section>
    );
}

function getSearchBasedPromotedEvents(search: EventSearch) {
    const { latitude, longitude, place_id, is_online } =
        computeLocationInfo(search);
    return {
        page: search.page || 1,
        search: {
            dates: ['current_future' as const],
            place: {
                is_online,
                place_id,
                geo_point:
                    longitude && latitude
                        ? getGeoPoint({ longitude, latitude })
                        : undefined,
            },
        },
    };
}
