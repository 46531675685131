import { BaseDiscoverState } from '@eventbrite/discover-utils';
import { connect } from 'react-redux';
import { handleGetPage as handleGetPageAction } from '../../redux/actions/footer/pagination';
import type { PaginationData, WithRouterProps } from '../../types';
import { constructSearchUrl } from '../../utils';

export interface SearchFooterProps extends WithRouterProps {
    pagination: PaginationData;
}

export type SearchFooterReduxProps = {
    isBotRequest: boolean;
    prevPageUrl: string;
    nextPageUrl: string;
    firstPageUrl: string;
    lastPageUrl: string;
    handleGetPage: Function;
};

const _mapStateToProps = (
    state: BaseDiscoverState,
    ownProps: SearchFooterProps,
) => {
    const search = new URLSearchParams(ownProps.router.query).toString();
    return {
        isBotRequest: state.app.isBotRequest,
        prevPageUrl: constructSearchUrl(
            ownProps.pagination.page - 1,
            state.search.eventSearch,
            state.location.slug,
            search,
        ),
        nextPageUrl: constructSearchUrl(
            ownProps.pagination.page + 1,
            state.search.eventSearch,
            state.location.slug,
            search,
        ),
        firstPageUrl: constructSearchUrl(
            0,
            state.search.eventSearch,
            state.location.slug,
            search,
        ),
        lastPageUrl: constructSearchUrl(
            ownProps.pagination.pageCount - 1,
            state.search.eventSearch,
            state.location.slug,
            search,
        ),
    };
};

const _mapDispatchToProps = {
    handleGetPage: handleGetPageAction,
};

export const connectSearchFooter = connect(
    _mapStateToProps,
    _mapDispatchToProps,
);
