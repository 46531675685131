import React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { OverlayProvider } from '../../context/Overlay';
import { DiscoverState } from '../../types';

type SearchPageProvidersProps = {
    store: Store<DiscoverState>;
    location: Location;
    children: React.ReactNode;
};

export function SearchPageProviders({
    store,
    children,
}: SearchPageProvidersProps) {
    return (
        <Provider store={store}>
            <OverlayProvider>{children}</OverlayProvider>
        </Provider>
    );
}
