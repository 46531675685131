import { Button } from '@eventbrite/eds-button';
import { isMediumWideDown } from '@eventbrite/eds-utils';
import { gettext } from '@eventbrite/i18n';
import { $FixMe } from '@eventbrite/ts-utils';
import loadable from '@loadable/component';
import React, { useEffect, useState } from 'react';
import { SearchResponseEventsData } from '../../../../utils/transformation';
import './LazyMapPanelContainer.scss';

export type LazyMapPanelContainerProps = {
    isOnline: boolean;
    isLoading: boolean;
    events: SearchResponseEventsData;
};

const LoadMapPanel = ({ onClick }: { onClick: () => void }) => (
    <div className="eds-show-up-mw static-map">
        <div
            style={{
                position: 'absolute',
                top: '33%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            <Button data-testid="load-map-panel-btn" onClick={onClick}>
                {gettext('View map')}
            </Button>
        </div>
    </div>
);

const lazyLoadMapPanel = () =>
    loadable(() => import('./MapPanelContainer'), {
        fallback: <div />,
        resolveComponent: (component: { MapPanelContainer: React.ReactNode }) =>
            component.MapPanelContainer,
    });

export const LazyMapPanelContainer = ({
    isOnline,
    ...props
}: LazyMapPanelContainerProps) => {
    const [LazyComponent, setLazyComponent] = useState<$FixMe>(null);

    useEffect(() => {
        // Ensure small screens never load the map
        if (isMediumWideDown() || isOnline) {
            setLazyComponent(null);
        } else {
            setLazyComponent(LazyComponent);
        }
    }, [LazyComponent, isOnline]);

    if (LazyComponent) {
        return <LazyComponent {...props} />;
    } else if (isOnline) {
        return null;
    }
    return (
        <LoadMapPanel
            onClick={() => {
                setLazyComponent(lazyLoadMapPanel());
            }}
        />
    );
};
