import { BreadCrumbItem, BreadCrumbs } from '@eventbrite/breadcrumbs-infra';
import { gettext } from '@eventbrite/i18n';
import React, { useMemo } from 'react';
import styles from './SearchBreadCrumb.module.scss';

export interface Breadcrumb {
    title: string;
    relative_url: string;
    absolute_url: string;
}

export interface BreadcrumbsProps {
    breadcrumbs?: Breadcrumb[];
    containerClasses?: string;
}

export const SearchBreadCrumb = ({
    breadcrumbs = [],
    containerClasses = 'search-breadcrumbs',
}: BreadcrumbsProps) => {
    const breadcrumbItems = useMemo<BreadCrumbItem<{}>[]>(() => {
        const items: BreadCrumbItem<{}>[] = breadcrumbs.map((breadcrumb) => {
            return {
                value: breadcrumb.relative_url,
                display: breadcrumb.title,
            };
        });
        return items;
    }, [breadcrumbs]);

    return (
        <BreadCrumbs
            containerClasses={containerClasses}
            items={breadcrumbItems}
            separator={<span className={styles.breadcrumbSeperator}>/</span>}
            ariaLabel={gettext('breadcrumbs')}
        >
            {(item: BreadCrumbItem<{}>) => (
                <a
                    href={item.value}
                    data-testid="breadcrumb-item"
                    title={item.display.toString()}
                >
                    {item.display}
                </a>
            )}
        </BreadCrumbs>
    );
};
