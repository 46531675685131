import { EVENTBRITE_FORMAT_MAPPING } from '@eventbrite/categories';
import { DATES_LOWERCASE_MAPPING } from '@eventbrite/discover-utils';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import { Typography } from '@eventbrite/marmalade';
import { SelectedFilters } from '@eventbrite/search-utils';
import { cloneDeep, slice } from 'lodash';
import React from 'react';
import { FREE_TAB } from '../../../../constants/tabConfig';
import { TrendingSearchApiData } from '../../../../types/TrendingSearch';
import { SearchResponseEventsData } from '../../../../utils/transformation';
import { FAQItem } from './FAQItem';
import {
    getBestEvents,
    getBestEventsFaq,
    getSortedOrganicEvents,
    getTrendingEventsFaq,
    getUpComingEventsFaq,
} from './utils';

import styles from './FAQSchema.module.scss';

interface HeaderProps {
    isOnline: boolean;
    location: string | GenericLazyString;
}

export interface FAQSectionProps {
    selectedFilters: SelectedFilters;
    isOnline: boolean;
    events: SearchResponseEventsData;
    location: string;
    trendingSearchesResult: TrendingSearchApiData[];
    query: string | GenericLazyString;
}

const _getFormattedTrendingSearch = (
    trendingSearchData: TrendingSearchApiData[],
) => {
    const formattedTrendingSearch = [];
    for (const trendingSearch of trendingSearchData.slice(0, 4)) {
        formattedTrendingSearch.push({
            name: trendingSearch.term,
            url: trendingSearch.search_path,
            id: trendingSearch.term,
        });
    }
    return formattedTrendingSearch;
};

const _getDate = (dates: string) => {
    const DATES_MAPPING: {
        [index: string]: GenericLazyString;
    } = DATES_LOWERCASE_MAPPING;
    return DATES_MAPPING[dates as string];
};

const constructFAQItemParams = (props: FAQSectionProps) => {
    const { isOnline, location } = props;
    const date = props.selectedFilters.dates
        ? _getDate(props.selectedFilters.dates)
        : '';
    const format = props.selectedFilters.format
        ? EVENTBRITE_FORMAT_MAPPING[props.selectedFilters.format]
        : '';
    const price =
        props.selectedFilters.price === FREE_TAB ? gettext('free') : '';
    return {
        isOnline: isOnline,
        price: price,
        format: format.toLocaleLowerCase(),
        date: date,
        city: location,
    };
};

const FAQHeader = (props: HeaderProps) => {
    const location = props.location;
    const headerText = props.isOnline
        ? gettext('Online Events FAQs | Eventbrite')
        : gettext('Frequently asked questions about %(location)s', {
              location,
          });
    return (
        <Typography variant="heading-sm" color="neutral-900">
            {headerText}
        </Typography>
    );
};

export const FAQSection = (props: FAQSectionProps) => {
    const trendingSearch = _getFormattedTrendingSearch(
        props.trendingSearchesResult,
    );
    const organicEvents = cloneDeep(props.events.organic);
    const sortedOrganicEvents = getSortedOrganicEvents(organicEvents);
    const nearApproachingEvents = slice(sortedOrganicEvents, 0, 4);
    const bestEvents = getBestEvents(organicEvents, nearApproachingEvents);
    const params = constructFAQItemParams(props);
    const showFaqSchema =
        nearApproachingEvents.length > 0 ||
        bestEvents.length > 0 ||
        trendingSearch.length > 0;

    if (props.query) {
        return null;
    }

    return showFaqSchema ? (
        <div className={styles.searchPageShelf}>
            <section itemScope itemType="https://schema.org/FAQPage">
                <FAQHeader
                    isOnline={props.isOnline}
                    location={props.location}
                />
                <div>
                    {bestEvents.length > 0 && (
                        <FAQItem
                            data={bestEvents}
                            {...getBestEventsFaq(params)}
                        />
                    )}
                    {trendingSearch.length > 0 && (
                        <FAQItem
                            data={trendingSearch}
                            {...getTrendingEventsFaq(params)}
                        />
                    )}
                    {nearApproachingEvents.length > 0 && (
                        <FAQItem
                            data={nearApproachingEvents}
                            {...getUpComingEventsFaq(params)}
                        />
                    )}
                </div>
            </section>
        </div>
    ) : null;
};
