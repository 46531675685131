import React from 'react';
import {
    DiscoverEventCardProps,
    EXPERIMENT_SEARCH_CARD_MAP_DESKTOP,
    EXPERIMENT_SEARCH_CARD_MAP_MOBILE,
} from '../../components/DiscoverEventCard';

import classNames from 'classnames';
import { useShowLikesExperiment } from '../../pages/search/hooks';
import './SearchEventCardStyleSwitcher.scss';

export type SearchEventCardStylesSwitcherProps = DiscoverEventCardProps & {
    forceVariant?: 'desktop' | 'mobile';
};

const mobileSearchShareOptions = {
    utmOptions: {
        'utm-campaign': 'social',
        'utm-content': 'attendeeshare',
        'utm-medium': 'discovery',
        'utm-share-source': 'mobile-search-results',
        'utm-term': '',
    },
};

export const SearchEventCardStyleSwitcher: React.FunctionComponent<
    SearchEventCardStylesSwitcherProps
> = ({ forceVariant, ...props }) => {
    const { MobileEventCard, DesktopEventCard } = useGetEventCardTypes();

    const showMobile = forceVariant !== 'desktop';
    const showDesktop = forceVariant !== 'mobile';
    const shouldHandleVisibility = !forceVariant;
    const isShowLikesExperimentEnabled = useShowLikesExperiment(
        props.event.isPromoted,
    );

    return (
        <>
            {showMobile && (
                <div
                    className={classNames(
                        'discover-search-mobile-card',
                        shouldHandleVisibility &&
                            'discover-search-mobile-card--hiddeable',
                    )}
                    key={`discover-mobile-event-card-${props.event.id}`}
                >
                    <MobileEventCard
                        {...props}
                        shareOptions={mobileSearchShareOptions}
                        isShowLikesExperimentEnabled={
                            isShowLikesExperimentEnabled
                        }
                    />
                </div>
            )}
            {showDesktop && (
                <div
                    className={classNames(
                        'discover-search-desktop-card',
                        shouldHandleVisibility &&
                            'discover-search-desktop-card--hiddeable',
                    )}
                    key={`discover-desktop-event-card-${props.event.id}`}
                >
                    <DesktopEventCard {...props} />
                </div>
            )}
        </>
    );
};

function useGetEventCardTypes() {
    const MobileEventCard = EXPERIMENT_SEARCH_CARD_MAP_MOBILE['control'];
    const DesktopEventCard = EXPERIMENT_SEARCH_CARD_MAP_DESKTOP['control'];

    return {
        MobileEventCard: MobileEventCard,
        DesktopEventCard: DesktopEventCard,
    };
}
