import {
    PromotedActionsMenuEventCard,
    PromotedActionsMenuEventCardProps,
} from '@eventbrite/ads-event-card-extra';
import React, { useEffect, useRef } from 'react';
import {
    SEARCH_EVENT_NAVIGATION,
    SEARCH_PROMOTED_LIST_NAVIGATION,
} from '../../../../../../constants/analytics';
import {
    SearchResultEventCard,
    SearchResultEventCardProps,
} from '../../../SearchResultEventCard';
import styles from './SearchResultPanelContentEventCard.module.scss';

export type SearchResultPanelEventContentCardProps = Omit<
    SearchResultEventCardProps,
    'heapLocationString'
> &
    Pick<
        PromotedActionsMenuEventCardProps,
        'getTrackingContext' | 'onRemoveEvent'
    > & {
        isRaised: boolean;
        scrollId?: string;
        setHoverEventTarget: Function;
        onTrackEventClickInternal: Function;
        position: number;
        page: number;
    };

export const SearchResultPanelContentEventCard: React.FunctionComponent<SearchResultPanelEventContentCardProps> =
    React.memo(
        ({
            scrollId,
            position,
            setHoverEventTarget,
            onTrackEventClickInternal,
            event,
            getTrackingContext,
            onRemoveEvent,
            ...otherProps
        }) => {
            const { handleTrackClickInternal } = useTrackingHandlers(
                event.isPromoted,
                {
                    onTrackEventClickInternal,
                },
            );
            const cardRef = useScrollIntoViewEffects(scrollId, event.id);
            const statsigLocationString = event.isPromoted
                ? 'Search - Promoted'
                : 'Search - Primary Results';

            return (
                <div
                    className={styles.card}
                    data-testid="search-event"
                    onMouseEnter={() => setHoverEventTarget(event.id)}
                    onFocus={() => setHoverEventTarget(event.id)}
                    onMouseLeave={() => setHoverEventTarget(null)}
                    onBlur={() => setHoverEventTarget(null)}
                    role="presentation"
                    ref={cardRef}
                    key={`result-panel-content-card-${event.id}-${event.isPromoted}`}
                >
                    <SearchResultEventCard
                        trackClickInternal={handleTrackClickInternal}
                        event={event}
                        statsigLocationString={statsigLocationString}
                        position={position}
                        moreActions={
                            event.isPromoted && (
                                <PromotedActionsMenuEventCard
                                    event={event}
                                    onRemoveEvent={onRemoveEvent}
                                    getTrackingContext={getTrackingContext}
                                />
                            )
                        }
                        {...otherProps}
                    />
                </div>
            );
        },
        areEqual,
    );

function areEqual(
    prevProps: SearchResultPanelEventContentCardProps,
    nextProps: SearchResultPanelEventContentCardProps,
) {
    //return true if no re-render
    //return false if should re-render
    if (
        prevProps.event.dedup !== nextProps.event.dedup ||
        prevProps.event.urgencySignals !== nextProps.event.urgencySignals ||
        prevProps.isRaised !== nextProps.isRaised ||
        (nextProps.scrollId === nextProps.event.id && !prevProps.scrollId) ||
        prevProps.event.organizerName !== nextProps.event.organizerName ||
        prevProps.event.imageUrl !== nextProps.event.imageUrl ||
        prevProps.event.name !== nextProps.event.name ||
        prevProps.isExpansionsLoading !== nextProps.isExpansionsLoading
    ) {
        return false;
    }

    return true;
}

function useScrollIntoViewEffects(scrollId: string | undefined, id: string) {
    const cardRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (scrollId === id && cardRef.current) {
            cardRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }, [scrollId, id]);

    return cardRef;
}

export function useTrackingHandlers(
    isPromoted: boolean | undefined,
    {
        onTrackEventClickInternal,
    }: Pick<
        SearchResultPanelEventContentCardProps,
        'onTrackEventClickInternal'
    >,
) {
    const handleTrackClickInternal = (
        position: number,
        eventId: string,
        ticketsBy: string,
    ) => {
        const trackingData = {
            clickRank: position,
            navigation: isPromoted
                ? SEARCH_PROMOTED_LIST_NAVIGATION
                : SEARCH_EVENT_NAVIGATION,
            itemId: eventId,
        };
        onTrackEventClickInternal(trackingData, ticketsBy);
    };

    return {
        handleTrackClickInternal,
    };
}
