import React, { useState } from 'react';

const OverlayContext = React.createContext<{
    setOverlayConfig: Function;
    getOverlayConfig: Function;
} | null>(null);

export const OverlayProvider = (props: { children: React.ReactNode }) => {
    const [config, setOverlayConfig]:
        | [config: any, setOverlayConfig: Function]
        | null[] = useState(null);

    const getOverlayConfig = () => {
        if (config) {
            return {
                ...config,
                onClose: () => setOverlayConfig(null),
                isShown: true,
            };
        }

        return config;
    };

    return (
        <OverlayContext.Provider value={{ setOverlayConfig, getOverlayConfig }}>
            {props.children}
        </OverlayContext.Provider>
    );
};

export default OverlayContext;
