import { getSelectedFormat } from '@eventbrite/search-utils';
import { connect } from 'react-redux';
import { getSelectedDate } from '../../../../redux/selectors/search';
import { DiscoverState } from '../../../../types';
import { FAQSection } from './FAQSection';

export const _mapStateToProps = (state: DiscoverState) => {
    return {
        location: state.location?.currentPlace,
        isOnline: state.location.isOnline,
        trendingSearchesResult: state.trendingSearchesResult?.trending,
        query: state.search.eventSearch?.q,
        selectedFilters: {
            price: state.search.eventSearch.price,
            format: getSelectedFormat(state.search.eventSearch.tags),
            dates: getSelectedDate(
                state.search.eventSearch.dates,
                state.search.eventSearch.dateRange,
            ),
        },
    };
};

export default connect(_mapStateToProps)(FAQSection);
