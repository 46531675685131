import { Card } from '@eventbrite/eds-card';
import { Trending as TrendingSvg } from '@eventbrite/eds-iconography';
import {
    GRID_TYPE,
    MediaCardContent,
    STANDARD_STYLE,
} from '@eventbrite/eds-media-card-content';
import { gettext } from '@eventbrite/i18n';
import upperFirst from 'lodash/upperFirst';
import React from 'react';
import { connect } from 'react-redux';
import { BucketHeader } from '../../../../components/BucketHeader';
import { SeeMoreButton } from '../../../../components/SeeMoreButton';
import { DiscoverState, TrendingSearchApiData } from '../../../../types';
import { getTrendingSearchesPathForLocation } from '../../../../utils/trending';

import styles from '../../SearchPageContent.module.scss';
import './TrendingSearchesContainer.scss';

export interface TrendingSearchesProps {
    trending: TrendingSearchApiData[];
    viewMoreMarkedNoFollow?: boolean;
    locationSlug: string;
    currentPlace: string;
    isOnline?: boolean;
}

const _formattedTitle = (id: number, searchTerm: string) => (
    <span className="eds-text-bl eds-text-weight--regular eds-text-bm eds-text-color--ui-blue">
        {id + 1}. {upperFirst(searchTerm)}
    </span>
);

const _getTrendingSearchCards = (trending: TrendingSearchApiData[]) => {
    const classes =
        'eds-l-pad-hor-3 eds-l-pad-vert-2 eds-g-cell eds-g-cell-md-3-12 eds-g-cell-12-12';

    return trending.map(({ id, term, search_path }) => (
        <div key={id} className={classes} data-testid="media-card">
            <Card style="simple" containerClassName="trending-searches-card">
                <MediaCardContent
                    style={STANDARD_STYLE}
                    type={GRID_TYPE}
                    linkUrl={search_path}
                    title={_formattedTitle(id, term)}
                    shouldHideImage={true}
                />
            </Card>
        </div>
    ));
};

export const TrendingSearches: React.FunctionComponent<
    TrendingSearchesProps
> = (props) => {
    const {
        locationSlug,
        currentPlace,
        trending,
        viewMoreMarkedNoFollow = false,
        isOnline = false,
    } = props;

    const trendingSearchesPath = getTrendingSearchesPathForLocation(
        locationSlug || '',
    );

    const trendingTitle = isOnline
        ? gettext('Trends Online')
        : gettext('Trends in %(currentPlace)s', {
              currentPlace: currentPlace,
          });

    const seeMoreText = gettext('Explore more trends').toString();

    return (
        <>
            {trending?.length ? (
                <div className={styles.searchPageShelf}>
                    <BucketHeader
                        title={trendingTitle}
                        linkUrl={trendingSearchesPath}
                        template="trending_searches"
                        seeMoreText={seeMoreText}
                        iconType={<TrendingSvg />}
                        seeMoreMarkedNoFollow={viewMoreMarkedNoFollow}
                    />
                    <div className="eds-g-group">
                        {_getTrendingSearchCards(trending)}
                    </div>
                    <div className="eds-l-pad-top-6">
                        <div className="eds-show-down-sn eds-align--center">
                            <div className="eds-g-cell">
                                <SeeMoreButton
                                    title={trendingTitle}
                                    linkUrl={trendingSearchesPath}
                                    seeMoreText={seeMoreText}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export const _mapStateToProps = (state: DiscoverState) => {
    return {
        locationSlug: state.location.slug,
        currentPlace: state.location.currentPlace,
        isOnline: state.location.isOnline,
        trending: state.trendingSearchesResult.trending,
        viewMoreMarkedNoFollow:
            state.trendingSearchesResult.viewMoreMarkedNoFollow,
    };
};

export const TrendingSearchesContainer =
    connect(_mapStateToProps)(TrendingSearches);
